import React, { useEffect, useState } from "react";
import Topbar from "../../components/new-design/Topbar";
import Sidebar from "../../components/new-design/Sidebar";
import { useSearchParams } from "react-router-dom";
import Search from "../../assets/new-design/flowbite_search-outline.svg";
import Chevron from "../../assets/new-design/ep_arrow-up-bold.svg";
import axios from "axios";
import { Triangle } from "react-loader-spinner";
import ReactPaginate from "react-paginate";
import { CSVLink } from "react-csv";
import More from "../../assets/new-design/uiw_more.svg";
import { Dialog } from "@headlessui/react";
import Close from "../../assets/new-design/ic_round-close.svg";

function convertDate(isoDate) {
  //   const isoDate = "2023-06-23T20:37:03.658Z";

  // Create a new Date object from the ISO string
  const date = new Date(isoDate);

  // Extract day, month, and year
  const day = String(date.getDate()).padStart(2, "0");
  const month = date.toLocaleString("default", { month: "long" });
  const year = date.getFullYear();

  // Format the date as "dd mm, yyyy"
  const formattedDate = `${day} ${month}, ${year}`;

  return formattedDate; // Output: 23 June, 2023
}

function Activities() {
  const [isOpen, setIsOpen] = useState(false);
  const [searchParams] = useSearchParams();
  const [users, setUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [filterBy, setFilterBy] = useState("");
  const [loading, setLoading] = useState(false);
  const [usersActivity, setUsersActivity] = useState([]);
  const [filteredUsersActivity, setFilteredUsersActivity] = useState([]);
  const [filterByActivity, setFilterByActivity] = useState("");
  const [loadingActivity, setLoadingActivity] = useState(false);

  const CSVdatauserActivity = usersActivity;

  const [itemOffsetUserActivity, setItemOffsetUserActivity] = useState(0);

  //   Number of items displayed on the table
  const itemsPerPageUserActivity = 10;

  const endOffsetUserActivity =
    itemOffsetUserActivity + itemsPerPageUserActivity;
  const currentItemsUserActivity =
    filteredUsersActivity?.length > 0 && filterByActivity !== ""
      ? filteredUsersActivity?.slice(
          itemOffsetUserActivity,
          endOffsetUserActivity
        )
      : filterByActivity === ""
      ? usersActivity?.slice(itemOffsetUserActivity, endOffsetUserActivity)
      : [];
  const pageCountUserActivity =
    filteredUsersActivity?.length > 0 && filterByActivity !== ""
      ? Math.ceil(filteredUsersActivity?.length / itemsPerPageUserActivity)
      : filterByActivity === ""
      ? Math.ceil(usersActivity?.length / itemsPerPageUserActivity)
      : Math.ceil([].length / itemsPerPageUserActivity);

  useEffect(() => {
    const getLearnings = async () => {
      setLoadingActivity(true);
      try {
        const res = await axios.get(
          "https://hero-backend-july10-72654a654b93.herokuapp.com/api/user-activity/user-activities"
        );
        console.log(res.data);
        setLoadingActivity(false);
        setUsersActivity(res.data);
      } catch (error) {
        console.log(error);
      }
    };
    getLearnings();
  }, []);

  useEffect(() => {
    const filteredResults = usersActivity.filter((i) =>
      i.userEmail.toLowerCase().includes(filterByActivity.toLowerCase())
    );
    setFilteredUsersActivity(filteredResults);
  }, [usersActivity, filterByActivity]);

  const handlePageClickUserActivity = (event) => {
    const newOffset =
      (event.selected * itemsPerPageUserActivity) % usersActivity.length;
    console.log(
      `User requested page number ${event.selected}, which is offset ${newOffset}`
    );
    setItemOffsetUserActivity(newOffset);
  };

  const CSVdata = users;

  const [itemOffset, setItemOffset] = useState(0);

  //   Number of items displayed on the table
  const itemsPerPage = 10;

  const endOffset = itemOffset + itemsPerPage;
  const currentItems =
    filteredUsers?.length > 0 && filterBy !== ""
      ? filteredUsers?.slice(itemOffset, endOffset)
      : filterBy === ""
      ? users?.slice(itemOffset, endOffset)
      : [];
  const pageCount =
    filteredUsers?.length > 0 && filterBy !== ""
      ? Math.ceil(filteredUsers?.length / itemsPerPage)
      : filterBy === ""
      ? Math.ceil(users?.length / itemsPerPage)
      : Math.ceil([].length / itemsPerPage);

  useEffect(() => {
    const getLearnings = async () => {
      setLoading(true);
      try {
        const res = await axios.get(
          "https://hero-backend-july10-72654a654b93.herokuapp.com/api/activity/activities"
        );
        console.log(res.data);
        setLoading(false);
        setUsers(res.data);
      } catch (error) {
        console.log(error);
      }
    };
    getLearnings();
  }, []);

  useEffect(() => {
    const filteredResults = users.filter((i) =>
      i.title.toLowerCase().includes(filterBy.toLowerCase())
    );
    setFilteredUsers(filteredResults);
  }, [users, filterBy]);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % users.length;
    console.log(
      `User requested page number ${event.selected}, which is offset ${newOffset}`
    );
    setItemOffset(newOffset);
  };
  return (
    <div className="flex h-[100vh]">
      <Sidebar />
      <div className="lg:w-[82%] w-[100%]">
        <Topbar />
        {searchParams.get("type") === "all" && (
          <div className="lg:p-[32px] p-[16px] h-[88vh] overflow-y-scroll">
            <div className="border border-[#E4E4E4] rounded-[8px] py-[24px] hidden lg:block">
              <div className="flex pr-[16px]">
                <button
                  onClick={() => setIsOpen(true)}
                  className="ml-auto bg-[#3EB211] text-white font-poppins px-[32px] py-[12px] font-semibold rounded-[6px] mb-[24px]"
                >
                  Add Activity
                </button>
              </div>
              <div className="flex justify-between px-[16px]">
                <h1 className="font-poppins text-[#333333] font-medium text-[1.125rem]">
                  Activity List
                </h1>
                <div className="flex items-center gap-10 text-[0.875rem]">
                  <CSVLink data={CSVdata} filename={"activities_data.csv"}>
                    <button className="text-[#3EB211] font-poppins font-medium">
                      Export CSV
                    </button>
                  </CSVLink>
                  <div className="flex items-center border-b border-b-[#1D1D1D] px-2 pb-2">
                    <img src={Search} alt="" />
                    <input
                      onChange={(e) => setFilterBy(e.target.value)}
                      type="text"
                      placeholder="Search"
                      className="px-3 outline-none font-poppins"
                    />
                  </div>
                  <button className="border border-[#E4E4E4] flex font-medium items-center font-poppins px-2 py-1 rounded-[8px] gap-[8px]">
                    Filter
                    <img src={Chevron} alt="" />
                  </button>
                  <ReactPaginate
                    breakLabel="..."
                    nextLabel=">"
                    nextClassName="font-bold text-black"
                    previousClassName="font-bold text-black"
                    onPageChange={handlePageClick}
                    pageRangeDisplayed={2}
                    pageCount={pageCount}
                    previousLabel="<"
                    // renderOnZeroPageCount={null}
                    marginPagesDisplayed={1}
                    activeClassName={
                      "bg-[#3EB211] rounded-full w-[24px] h-[20px] text-white flex items-center justify-center"
                    }
                    className="flex gap-4 text-[16px] items-center text-[#62646A99] font-poppins"
                  />
                </div>
              </div>
              {loading ? (
                <div className="w-[100%] h-[50vh] flex items-center justify-center">
                  <Triangle
                    visible={true}
                    height="80"
                    width="80"
                    color="#3EB211"
                    ariaLabel="triangle-loading"
                    wrapperStyle={{}}
                    wrapperClass=""
                  />
                </div>
              ) : (
                <div className="grid grid-cols-2 gap-x-4 gap-y-6 p-4 font-poppins">
                  {currentItems.map((use) => (
                    <div
                      key={use._id}
                      className="flex border items-start gap-6 border-[#E4E4E4] rounded-[8px] p-6"
                    >
                      <img
                        className="w-[94px] h-[67px]"
                        src={use.fileUrl}
                        alt=""
                      />
                      <div>
                        <p className="text-[#333333] font-medium text-[0.875rem] mb-2.5">
                          {use.title}
                        </p>
                        <div className="mb-2.5">
                          <p className="mb-[2px] text-[#62646A99] font-medium text-[0.75rem]">
                            REWARD
                          </p>
                          <p className="text-[#333333] font-medium text-[0.875rem]">
                            {use.description}
                          </p>
                        </div>
                        <div className="mb-2.5">
                          <p className="mb-[2px] text-[#62646A99] font-medium text-[0.75rem]">
                            DEADLINE
                          </p>
                          <p className="text-[#333333] font-medium text-[0.875rem]">
                            {use.deadline}
                          </p>
                        </div>
                      </div>
                      <img src={More} className="ml-auto" alt="" />
                    </div>
                  ))}
                </div>
              )}
            </div>

            <div className="lg:hidden">
              <div className="flex items-center justify-between">
                <h1 className="font-poppins text-[#333333] font-medium text-[1.125rem]">
                  Activity List
                </h1>

                <CSVLink data={CSVdata} filename={"activities_data.csv"}>
                  <button className="text-[#3EB211] font-poppins font-medium">
                    Export CSV
                  </button>
                </CSVLink>
              </div>
              <div className="flex flex-col items-center gap-10 text-[0.875rem] mt-[24px]">
                <div className="flex items-center justify-between">
                  <div className="flex items-center w-[50%] border-b border-b-[#1D1D1D] px-2 pb-2">
                    <img src={Search} alt="" />
                    <input
                      onChange={(e) => setFilterBy(e.target.value)}
                      type="text"
                      placeholder="Search"
                      className="px-3 outline-none font-poppins"
                    />
                  </div>
                  <button className="border border-[#E4E4E4] flex font-medium items-center font-poppins px-2 py-1 rounded-[8px] gap-[8px]">
                    Filter
                    <img src={Chevron} alt="" />
                  </button>
                </div>
                <ReactPaginate
                  breakLabel="..."
                  nextLabel=">"
                  nextClassName="font-bold text-black"
                  previousClassName="font-bold text-black"
                  onPageChange={handlePageClick}
                  pageRangeDisplayed={5}
                  pageCount={pageCount}
                  previousLabel="<"
                  // renderOnZeroPageCount={null}
                  marginPagesDisplayed={1}
                  activeClassName={
                    "bg-[#3EB211] rounded-full w-[24px] h-[20px] text-white flex items-center justify-center"
                  }
                  className="flex gap-4 text-[16px] items-center text-[#62646A99] font-poppins"
                />
              </div>
              {loading ? (
                <div className="w-[100%] h-[50vh] flex items-center justify-center">
                  <Triangle
                    visible={true}
                    height="80"
                    width="80"
                    color="#3EB211"
                    ariaLabel="triangle-loading"
                    wrapperStyle={{}}
                    wrapperClass=""
                  />
                </div>
              ) : (
                <div className="grid gap-x-4 gap-y-6 py-4 font-poppins">
                  {currentItems.map((use) => (
                    <div
                      key={use._id}
                      className="flex border items-start gap-6 border-[#E4E4E4] rounded-[8px] p-6"
                    >
                      <img
                        className="w-[94px] h-[67px]"
                        src={use.fileUrl}
                        alt=""
                      />
                      <div>
                        <p className="text-[#333333] font-medium text-[0.875rem] mb-2.5 break-all">
                          {use.title}
                        </p>
                        <div className="mb-2.5">
                          <p className="mb-[2px] text-[#62646A99] font-medium text-[0.75rem]">
                            REWARD
                          </p>
                          <p className="text-[#333333] font-medium text-[0.875rem]">
                            {use.description}
                          </p>
                        </div>
                        <div className="mb-2.5">
                          <p className="mb-[2px] text-[#62646A99] font-medium text-[0.75rem]">
                            DEADLINE
                          </p>
                          <p className="text-[#333333] font-medium text-[0.875rem]">
                            {use.deadline}
                          </p>
                        </div>
                      </div>
                      <img src={More} className="ml-auto" alt="" />
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
        )}
        {searchParams.get("type") === "proof" && (
          <div className="lg:p-[32px] p-[16px] h-[88vh] overflow-y-scroll">
            <div className="border border-[#E4E4E4] rounded-[8px] py-[24px] hidden lg:block">
              <div className="flex pr-[16px]">
                <button
                  onClick={() => setIsOpen(true)}
                  className="ml-auto bg-[#3EB211] text-white font-poppins px-[32px] py-[12px] font-semibold rounded-[6px] mb-[24px]"
                >
                  Add Activity
                </button>
              </div>
              <div className="flex justify-between px-[16px]">
                <h1 className="font-poppins text-[#333333] font-medium text-[1.125rem]">
                  Proof of Activity List
                </h1>
                <div className="flex items-center gap-10 text-[0.875rem]">
                  <CSVLink
                    data={CSVdatauserActivity}
                    filename={"users_proof_of_activity_data.csv"}
                  >
                    <button className="text-[#3EB211] font-poppins font-medium">
                      Export CSV
                    </button>
                  </CSVLink>
                  <div className="flex items-center border-b border-b-[#1D1D1D] px-2 pb-2">
                    <img src={Search} alt="" />
                    <input
                      onChange={(e) => setFilterByActivity(e.target.value)}
                      type="text"
                      placeholder="Search"
                      className="px-3 outline-none font-poppins"
                    />
                  </div>
                  <button className="border border-[#E4E4E4] flex font-medium items-center font-poppins px-2 py-1 rounded-[8px] gap-[8px]">
                    Filter
                    <img src={Chevron} alt="" />
                  </button>
                  <ReactPaginate
                    breakLabel="..."
                    nextLabel=">"
                    nextClassName="font-bold text-black"
                    previousClassName="font-bold text-black"
                    onPageChange={handlePageClickUserActivity}
                    pageRangeDisplayed={2}
                    pageCount={pageCountUserActivity}
                    previousLabel="<"
                    // renderOnZeroPageCount={null}
                    marginPagesDisplayed={1}
                    activeClassName={
                      "bg-[#3EB211] rounded-full w-[24px] h-[20px] text-white flex items-center justify-center"
                    }
                    className="flex gap-4 text-[16px] items-center text-[#62646A99] font-poppins"
                  />
                </div>
              </div>
              {loadingActivity ? (
                <div className="w-[100%] h-[50vh] flex items-center justify-center">
                  <Triangle
                    visible={true}
                    height="80"
                    width="80"
                    color="#3EB211"
                    ariaLabel="triangle-loading"
                    wrapperStyle={{}}
                    wrapperClass=""
                  />
                </div>
              ) : (
                <div className="grid grid-cols-2 gap-x-4 gap-y-6 p-4 font-poppins">
                  {currentItemsUserActivity.map((use) => (
                    <div
                      key={use._id}
                      className="flex border items-start gap-6 border-[#E4E4E4] rounded-[8px] p-6"
                    >
                      <img
                        className="w-[94px] h-[67px]"
                        src={use.fileUrl}
                        alt=""
                      />
                      <div>
                        <p className="mb-[2px] text-[#62646A99] font-medium text-[0.75rem]">
                          {convertDate(use.createdAt)}
                        </p>
                        <p className="text-[#333333] font-medium text-[0.875rem] my-2.5">
                          {use.userEmail}
                        </p>
                        <div className="my-2.5">
                          <p className="text-[#333333] font-medium text-[0.875rem]">
                            {use.activityTitle}
                          </p>
                        </div>
                        {/* <div className="mb-2.5">
                          <p className="mb-[2px] text-[#62646A99] font-medium text-[0.75rem]">
                            DEADLINE
                          </p>
                          <p className="text-[#333333] font-medium text-[0.875rem]">
                            {use.date}
                          </p>
                        </div> */}
                      </div>
                      <img src={More} className="ml-auto" alt="" />
                    </div>
                  ))}
                </div>
              )}
            </div>

            <div className="lg:hidden">
              <div className="flex items-center justify-between">
                <h1 className="font-poppins text-[#333333] font-medium text-[1.125rem]">
                  Proof of Activity List
                </h1>

                <CSVLink
                  data={CSVdata}
                  filename={"users_proof_of_activity_data.csv"}
                >
                  <button className="text-[#3EB211] font-poppins font-medium">
                    Export CSV
                  </button>
                </CSVLink>
              </div>
              <div className="flex flex-col items-center gap-10 text-[0.875rem] mt-[24px]">
                <div className="flex items-center justify-between">
                  <div className="flex items-center w-[50%] border-b border-b-[#1D1D1D] px-2 pb-2">
                    <img src={Search} alt="" />
                    <input
                      onChange={(e) => setFilterByActivity(e.target.value)}
                      type="text"
                      placeholder="Search"
                      className="px-3 outline-none font-poppins"
                    />
                  </div>
                  <button className="border border-[#E4E4E4] flex font-medium items-center font-poppins px-2 py-1 rounded-[8px] gap-[8px]">
                    Filter
                    <img src={Chevron} alt="" />
                  </button>
                </div>
                <ReactPaginate
                  breakLabel="..."
                  nextLabel=">"
                  nextClassName="font-bold text-black"
                  previousClassName="font-bold text-black"
                  onPageChange={handlePageClickUserActivity}
                  pageRangeDisplayed={5}
                  pageCount={pageCountUserActivity}
                  previousLabel="<"
                  // renderOnZeroPageCount={null}
                  marginPagesDisplayed={1}
                  activeClassName={
                    "bg-[#3EB211] rounded-full w-[24px] h-[20px] text-white flex items-center justify-center"
                  }
                  className="flex gap-4 text-[16px] items-center text-[#62646A99] font-poppins"
                />
              </div>
              {loadingActivity ? (
                <div className="w-[100%] h-[50vh] flex items-center justify-center">
                  <Triangle
                    visible={true}
                    height="80"
                    width="80"
                    color="#3EB211"
                    ariaLabel="triangle-loading"
                    wrapperStyle={{}}
                    wrapperClass=""
                  />
                </div>
              ) : (
                <div className="grid gap-x-4 gap-y-6 py-4 font-poppins">
                  {currentItemsUserActivity.map((use) => (
                    <div
                      key={use._id}
                      className="flex border items-start gap-6 border-[#E4E4E4] rounded-[8px] p-6"
                    >
                      <img
                        className="w-[94px] h-[67px]"
                        src={use.fileUrl}
                        alt=""
                      />
                      <div>
                        <p className="mb-[2px] text-[#62646A99] font-medium text-[0.75rem]">
                          {convertDate(use.createdAt)}
                        </p>
                        <p className="text-[#333333] font-medium text-[0.875rem] my-2.5">
                          {use.userEmail}
                        </p>
                        <div className="my-2.5">
                          <p className="text-[#333333] font-medium text-[0.875rem]">
                            {use.activityTitle}
                          </p>
                        </div>
                        {/* <div className="mb-2.5">
                          <p className="mb-[2px] text-[#62646A99] font-medium text-[0.75rem]">
                            DEADLINE
                          </p>
                          <p className="text-[#333333] font-medium text-[0.875rem]">
                            {use.date}
                          </p>
                        </div> */}
                      </div>
                      <img src={More} className="ml-auto" alt="" />
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
        )}
        {searchParams.get("type") === "rewards" && (
          <div>All Activities Rewards</div>
        )}
      </div>
      <Dialog
        open={isOpen}
        onClose={() => {
          setIsOpen(false);
        }}
        className="relative z-50"
      >
        <div className="fixed inset-0 flex w-screen items-center bg-black/70 justify-center p-4">
          <Dialog.Panel className="max-w-lg space-y-6 border bg-white lg:p-5 p-3 rounded-[8px] lg:w-[620px] w-[90%] font-poppins">
            <div className="flex items-center justify-end mb-[24px]">
              <img
                className="cursor-pointer"
                onClick={() => {
                  setIsOpen(false);
                }}
                src={Close}
                alt=""
              />
            </div>
            <div className="flex flex-col items-center justify-center gap-[8px] border-b border-b-[#E4E4E4] pb-[24px]">
              <h1 className="text-center font-medium text-[1.125rem]">
                Activity Details
              </h1>
            </div>
            <div className="border space-y-6 border-[#E4E4E4] rounded-[8px] p-[24px] mt-[24px]">
              <div className="flex flex-col gap-2">
                <label
                  className="font-medium text-[0.75rem] text-[#62646A99] cursor-pointer"
                  htmlFor="activity_create"
                >
                  <p className="font-poppins text-[#3EB211] font-semibold text-[0.875rem]">
                    Choose File <span className="text-[#333333] font-medium">No file chosen</span>
                  </p>
                </label>
                <input
                  id="activity_create"
                  className="border hidden border-[#333333] outline-none rounded px-3 py-1.5 text-[0.75rem]"
                  type="file"
                />
              </div>
              <div className="flex flex-col gap-2">
                <label
                  className="font-medium text-[0.75rem] text-[#62646A99]"
                  htmlFor=""
                >
                  Title
                </label>
                <input
                  className="border border-[#333333] outline-none rounded px-3 py-1.5 text-[0.75rem]"
                  type="text"
                />
              </div>
              <div className="flex flex-col gap-2">
                <label
                  className="font-medium text-[0.75rem] text-[#62646A99]"
                  htmlFor=""
                >
                  Reward
                </label>
                <input
                  className="border border-[#333333] outline-none rounded px-3 py-1.5 text-[0.75rem]"
                  type="text"
                />
              </div>
              <div className="flex flex-col gap-2">
                <label
                  className="font-medium text-[0.75rem] text-[#62646A99]"
                  htmlFor=""
                >
                  Deadline
                </label>
                <input
                  className="border border-[#333333] outline-none rounded px-3 py-1.5 text-[0.75rem]"
                  type="text"
                />
              </div>
            </div>

            <button
              className="w-[100%] bg-[#3EB211] mt-[24px] text-white font-semibold text-[0.875rem] h-[44px] rounded-[6px]"
              onClick={() => setIsOpen(false)}
            >
              Save
            </button>

            <button
              className="w-[100%] bg-[#F1F1F1] text-[#333333] mt-[24px font-semibold text-[0.875rem] h-[44px] rounded-[6px]"
              onClick={() => setIsOpen(false)}
            >
              Cancel
            </button>
          </Dialog.Panel>
        </div>
      </Dialog>
    </div>
  );
}

export default Activities;
