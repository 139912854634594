import React, { useEffect, useState } from "react";
import Topbar from "../../components/new-design/Topbar";
import Sidebar from "../../components/new-design/Sidebar";
import { useSearchParams } from "react-router-dom";
import ReactPaginate from "react-paginate";
import { CSVLink } from "react-csv";
import { Triangle } from "react-loader-spinner";
import Search from "../../assets/new-design/flowbite_search-outline.svg";
import Chevron from "../../assets/new-design/ep_arrow-up-bold.svg";
import QuizTable from "../../components/new-design/Tables/QuizTable";
import axios from "axios";
import QuizResultsTable from "../../components/new-design/Tables/QuizResultsTable";

const months = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];
const years = [2022, 2023, 2024, 2025, 2026, 2027, 2028, 2029, 2030];

function Quizzes() {
  const [searchParams] = useSearchParams();
  const [filterBy, setFilterBy] = useState("");
  const [filterByResults, setFilterByResults] = useState("");
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingResults, setLoadingResults] = useState(false);
  const [filteredData, setFilteredData] = useState([]);
  const [filteredDataResults, setFilteredDataResults] = useState([]);
  const [openFilter, setOpenFilter] = useState(false);
  const [addQuiz, setAddQuiz] = useState(false);
  const [results, setResults] = useState([]);

  const [selectedMonthIndex, setSelectedMonthIndex] = useState(1); // Index starts from 0
  const [selectedYearIndex, setSelectedYearIndex] = useState(2); // Index starts from 0
  const [startMonthIndex, setStartMonthIndex] = useState(0);
  const [startYearIndex, setStartYearIndex] = useState(0);

  const handleMonthChange = (direction) => {
    if (direction === "prev") {
      setStartMonthIndex((prev) => (prev - 3 < 0 ? 9 : prev - 3));
    } else {
      setStartMonthIndex((prev) => (prev + 3 > 11 ? 0 : prev + 3));
    }
  };

  const handleYearChange = (direction) => {
    if (direction === "prev") {
      setStartYearIndex((prev) => (prev - 3 < 0 ? years.length - 3 : prev - 3));
    } else {
      setStartYearIndex((prev) => (prev + 3 >= years.length ? 0 : prev + 3));
    }
  };

  const displayedMonths = months.slice(startMonthIndex, startMonthIndex + 3);
  const displayedYears = years.slice(startYearIndex, startYearIndex + 3);

  const CSVdataresults = results;

  const [itemOffsetResults, setItemOffsetResults] = useState(0);

  //   Number of items displayed on the table
  const itemsPerPageResults = 10;

  const endOffsetResults = itemOffsetResults + itemsPerPageResults;
  const currentItemsresults =
    filteredDataResults?.length > 0 && filterByResults !== ""
      ? filteredDataResults?.slice(itemOffsetResults, endOffsetResults)
      : filterByResults === ""
      ? results?.slice(itemOffsetResults, endOffsetResults)
      : [];
  const pageCountresults =
    filteredDataResults?.length > 0 && filterBy !== ""
      ? Math.ceil(filteredDataResults?.length / itemsPerPageResults)
      : filterByResults === ""
      ? Math.ceil(results?.length / itemsPerPageResults)
      : Math.ceil([].length / itemsPerPageResults);

  useEffect(() => {
    const getLearnings = async () => {
      setLoadingResults(true);
      try {
        const res = await axios.get(
          "https://hero-backend-july10-72654a654b93.herokuapp.com/api/user-quiz-result/user-quiz-results"
        );
        console.log(res.data);
        setLoadingResults(false);
        setResults(res.data.reverse());
      } catch (error) {
        console.log(error);
      }
    };
    getLearnings();
  }, []);

  useEffect(() => {
    if (filterByResults === "filter") {
      const filteredResults = results?.filter(
        (i) =>
          i.quizYear === years[selectedYearIndex].toString() &&
          i.quizMonth === months[selectedMonthIndex]
      );
      console.log(filteredResults);
      setFilteredDataResults(filteredResults);
    } else {
      const filteredResults = results?.filter((i) =>
        i.userEmail.toLowerCase().includes(filterByResults)
      );
      console.log(filteredResults);
      setFilteredDataResults(filteredResults);
    }
  }, [results, filterByResults, selectedMonthIndex, selectedYearIndex]);

  const handlePageClickResults = (event) => {
    const newOffset = (event.selected * itemsPerPage) % data.length;
    console.log(
      `User requested page number ${event.selected}, which is offset ${newOffset}`
    );
    setItemOffsetResults(newOffset);
  };

  const CSVdata = data;

  const [itemOffset, setItemOffset] = useState(0);

  //   Number of items displayed on the table
  const itemsPerPage = 10;

  const endOffset = itemOffset + itemsPerPage;
  const currentItems =
    filteredData?.length > 0 && filterBy !== ""
      ? filteredData?.slice(itemOffset, endOffset)
      : filterBy === ""
      ? data?.slice(itemOffset, endOffset)
      : [];
  const pageCount =
    filteredData?.length > 0 && filterBy !== ""
      ? Math.ceil(filteredData?.length / itemsPerPage)
      : filterBy === ""
      ? Math.ceil(data?.length / itemsPerPage)
      : Math.ceil([].length / itemsPerPage);

  useEffect(() => {
    const getLearnings = async () => {
      setLoading(true);
      try {
        const res = await axios.get(
          "https://hero-backend-july10-72654a654b93.herokuapp.com/api/quiz/quizzes"
        );
        console.log(res.data);
        setLoading(false);
        setData(res.data.reverse());
      } catch (error) {
        console.log(error);
      }
    };
    getLearnings();
  }, []);

  useEffect(() => {
    if (filterBy === "filter") {
      const filteredResults = data?.filter(
        (i) =>
          i.year === years[selectedYearIndex].toString() &&
          i.month === months[selectedMonthIndex]
      );
      console.log(filteredResults);
      setFilteredData(filteredResults);
    } else {
      const filteredResults = data?.filter((i) =>
        i.question.toLowerCase().includes(filterBy)
      );
      console.log(filteredResults);
      setFilteredData(filteredResults);
    }
  }, [data, filterBy, selectedMonthIndex, selectedYearIndex]);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % data.length;
    console.log(
      `User requested page number ${event.selected}, which is offset ${newOffset}`
    );
    setItemOffset(newOffset);
  };

  return (
    <div
      className="flex h-[100vh]"
      onClick={() => {
        setOpenFilter(false);
        setFilterBy("");
        setFilterByResults("");
      }}
    >
      <Sidebar />
      <div className="lg:w-[82%] w-[100%]">
        <Topbar />
        {searchParams.get("type") === "all" && (
          <div className="lg:p-[32px] p-[16px] h-[88vh] overflow-y-scroll">
            <div className="border border-[#E4E4E4] rounded-[8px] py-[24px] hidden lg:block">
              <div className="flex pr-[16px]">
                <button
                  onClick={() => setAddQuiz(true)}
                  className="ml-auto bg-[#3EB211] text-white font-poppins px-[32px] py-[12px] font-semibold rounded-[6px] mb-[24px]"
                >
                  Add Quiz
                </button>
              </div>
              <div className="flex justify-between px-[16px]">
                <h1 className="font-poppins text-[#333333] font-medium text-[1.125rem]">
                  Quiz List
                </h1>
                <div className="flex items-center gap-10 text-[0.875rem]">
                  <CSVLink data={CSVdata} filename={"quizzes_data.csv"}>
                    <button className="text-[#3EB211] font-poppins font-medium">
                      Export CSV
                    </button>
                  </CSVLink>
                  <div className="flex items-center border-b border-b-[#1D1D1D] px-2 pb-2">
                    <img src={Search} alt="" />
                    <input
                      onChange={(e) => setFilterBy(e.target.value)}
                      type="text"
                      placeholder="Search"
                      className="px-3 outline-none font-poppins"
                    />
                  </div>
                  <button
                    onClick={(e) => {
                      e.stopPropagation();
                      setOpenFilter(!openFilter);
                    }}
                    className="border relative border-[#E4E4E4] flex font-medium items-center font-poppins px-2 py-1 rounded-[8px] gap-[8px]"
                  >
                    Filter
                    <img src={Chevron} alt="" />
                    {openFilter && (
                      <div className="filter-component absolute -left-[5rem] bg-white p-[16px] rounded-[8px] shadow-xl border border-[#D8D8D8]">
                        <div className="filter-row">
                          <p className="filter-label font-poppins font-normal text-[0.875rem]">
                            Filter by: Month
                          </p>
                          <div className="filter-control">
                            <button
                              onClick={(e) => {
                                e.stopPropagation();
                                handleMonthChange("prev");
                              }}
                            >
                              {"<"}
                            </button>
                            {displayedMonths.map((month, index) => (
                              <label key={month}>
                                <input
                                  type="radio"
                                  name="month"
                                  value={month}
                                  checked={
                                    months[startMonthIndex + index] ===
                                    months[selectedMonthIndex]
                                  }
                                  onChange={() => {
                                    setFilterBy("filter");
                                    setSelectedMonthIndex(
                                      startMonthIndex + index
                                    );
                                  }}
                                />
                                {month}
                              </label>
                            ))}
                            <button
                              onClick={(e) => {
                                e.stopPropagation();
                                handleMonthChange("next");
                              }}
                            >
                              {">"}
                            </button>
                          </div>
                        </div>
                        <div className="filter-row">
                          <span className="filter-label font-poppins font-normal text-[0.875rem]">
                            Filter by: Year
                          </span>
                          <div className="filter-control">
                            <button
                              onClick={(e) => {
                                e.stopPropagation();
                                handleYearChange("prev");
                              }}
                            >
                              {"<"}
                            </button>
                            {displayedYears.map((year, index) => (
                              <label key={year}>
                                <input
                                  type="radio"
                                  name="year"
                                  value={year}
                                  checked={
                                    years[startYearIndex + index] ===
                                    years[selectedYearIndex]
                                  }
                                  onChange={() => {
                                    setFilterBy("filter");
                                    setSelectedYearIndex(
                                      startYearIndex + index
                                    );
                                  }}
                                />
                                {year}
                              </label>
                            ))}
                            <button
                              onClick={(e) => {
                                e.stopPropagation();
                                handleYearChange("next");
                              }}
                            >
                              {">"}
                            </button>
                          </div>
                        </div>
                      </div>
                    )}
                  </button>
                  <ReactPaginate
                    breakLabel="..."
                    nextLabel=">"
                    nextClassName="font-bold text-black"
                    previousClassName="font-bold text-black"
                    onPageChange={handlePageClick}
                    pageRangeDisplayed={2}
                    pageCount={pageCount}
                    previousLabel="<"
                    // renderOnZeroPageCount={null}
                    marginPagesDisplayed={1}
                    activeClassName={
                      "bg-[#3EB211] rounded-full w-[24px] h-[20px] text-white flex items-center justify-center"
                    }
                    className="flex gap-4 text-[16px] items-center text-[#62646A99] font-poppins"
                  />
                </div>
              </div>
              {loading ? (
                <div className="w-[100%] h-[50vh] flex items-center justify-center">
                  <Triangle
                    visible={true}
                    height="80"
                    width="80"
                    color="#3EB211"
                    ariaLabel="triangle-loading"
                    wrapperStyle={{}}
                    wrapperClass=""
                  />
                </div>
              ) : (
                <QuizTable
                  data={currentItems}
                  addQuiz={addQuiz}
                  setAddQuiz={setAddQuiz}
                />
              )}
            </div>
            <div className="lg:hidden">
              <div className="flex">
                <button
                  onClick={() => setAddQuiz(true)}
                  className="ml-auto bg-[#3EB211] text-white font-poppins px-[32px] py-[12px] font-semibold rounded-[6px] mb-[24px]"
                >
                  Add Quiz
                </button>
              </div>
              <div className="flex items-center justify-between">
                <h1 className="font-poppins text-[#333333] font-medium text-[1.125rem]">
                  Quiz List
                </h1>

                <CSVLink data={CSVdata} filename={"quizzes_data.csv"}>
                  <button className="text-[#3EB211] font-poppins font-medium">
                    Export CSV
                  </button>
                </CSVLink>
              </div>
              <div className="flex flex-col items-center gap-10 text-[0.875rem] mt-[24px]">
                <div className="flex items-center justify-between">
                  <div className="flex items-center w-[50%] border-b border-b-[#1D1D1D] px-2 pb-2">
                    <img src={Search} alt="" />
                    <input
                      onChange={(e) => setFilterBy(e.target.value)}
                      type="text"
                      placeholder="Search"
                      className="px-3 outline-none font-poppins"
                    />
                  </div>
                  <button
                    onClick={(e) => {
                      e.stopPropagation();
                      setOpenFilter(!openFilter);
                    }}
                    className="border relative border-[#E4E4E4] flex font-medium items-center font-poppins px-2 py-1 rounded-[8px] gap-[8px]"
                  >
                    Filter
                    <img src={Chevron} alt="" />
                    {openFilter && (
                      <div className="filter-component absolute lg:-left-[5rem] -left-[13rem] bg-white p-[16px] rounded-[8px] shadow-xl border border-[#D8D8D8]">
                        <div className="filter-row">
                          <p className="filter-label font-poppins font-normal text-[0.875rem]">
                            Filter by: Month
                          </p>
                          <div className="filter-control">
                            <button
                              onClick={(e) => {
                                e.stopPropagation();
                                handleMonthChange("prev");
                              }}
                            >
                              {"<"}
                            </button>
                            {displayedMonths.map((month, index) => (
                              <label key={month}>
                                <input
                                  type="radio"
                                  name="month"
                                  value={month}
                                  checked={
                                    months[startMonthIndex + index] ===
                                    months[selectedMonthIndex]
                                  }
                                  onChange={() => {
                                    setFilterBy("filter");
                                    setSelectedMonthIndex(
                                      startMonthIndex + index
                                    );
                                  }}
                                />
                                {month}
                              </label>
                            ))}
                            <button
                              onClick={(e) => {
                                e.stopPropagation();
                                handleMonthChange("next");
                              }}
                            >
                              {">"}
                            </button>
                          </div>
                        </div>
                        <div className="filter-row">
                          <span className="filter-label font-poppins font-normal text-[0.875rem]">
                            Filter by: Year
                          </span>
                          <div className="filter-control">
                            <button
                              onClick={(e) => {
                                e.stopPropagation();
                                handleYearChange("prev");
                              }}
                            >
                              {"<"}
                            </button>
                            {displayedYears.map((year, index) => (
                              <label key={year}>
                                <input
                                  type="radio"
                                  name="year"
                                  value={year}
                                  checked={
                                    years[startYearIndex + index] ===
                                    years[selectedYearIndex]
                                  }
                                  onChange={() => {
                                    setFilterBy("filter");
                                    setSelectedYearIndex(
                                      startYearIndex + index
                                    );
                                  }}
                                />
                                {year}
                              </label>
                            ))}
                            <button
                              onClick={(e) => {
                                e.stopPropagation();
                                handleYearChange("next");
                              }}
                            >
                              {">"}
                            </button>
                          </div>
                        </div>
                      </div>
                    )}
                  </button>
                </div>
                <ReactPaginate
                  breakLabel="..."
                  nextLabel=">"
                  nextClassName="font-bold text-black"
                  previousClassName="font-bold text-black"
                  onPageChange={handlePageClick}
                  pageRangeDisplayed={5}
                  pageCount={pageCount}
                  previousLabel="<"
                  // renderOnZeroPageCount={null}
                  marginPagesDisplayed={1}
                  activeClassName={
                    "bg-[#3EB211] rounded-full w-[24px] h-[20px] text-white flex items-center justify-center"
                  }
                  className="flex gap-4 text-[16px] items-center text-[#62646A99] font-poppins"
                />
              </div>
              {loading ? (
                <div className="w-[100%] h-[100vh] flex items-center justify-center">
                  <Triangle
                    visible={true}
                    height="80"
                    width="80"
                    color="#3EB211"
                    ariaLabel="triangle-loading"
                    wrapperStyle={{}}
                    wrapperClass=""
                  />
                </div>
              ) : (
                <QuizTable
                  data={currentItems}
                  addQuiz={addQuiz}
                  setAddQuiz={setAddQuiz}
                />
              )}
            </div>
          </div>
        )}
        {searchParams.get("type") === "results" && (
          <div className="lg:p-[32px] p-[16px] h-[88vh] overflow-y-scroll">
            <div className="border border-[#E4E4E4] rounded-[8px] py-[24px] hidden lg:block">
              {/* <div className="flex pr-[16px]">
                <button
                  onClick={() => setAddQuiz(true)}
                  className="ml-auto bg-[#3EB211] text-white font-poppins px-[32px] py-[12px] font-semibold rounded-[6px] mb-[24px]"
                >
                  Add Quiz
                </button>
              </div> */}
              <div className="flex justify-between px-[16px]">
                <h1 className="font-poppins text-[#333333] font-medium text-[1.125rem]">
                  Quiz Results List
                </h1>
                <div className="flex items-center gap-10 text-[0.875rem]">
                  <CSVLink data={CSVdataresults} filename={"data_data.csv"}>
                    <button className="text-[#3EB211] font-poppins font-medium">
                      Export CSV
                    </button>
                  </CSVLink>
                  <div className="flex items-center border-b border-b-[#1D1D1D] px-2 pb-2">
                    <img src={Search} alt="" />
                    <input
                      onChange={(e) => setFilterByResults(e.target.value)}
                      type="text"
                      placeholder="Search"
                      className="px-3 outline-none font-poppins"
                    />
                  </div>
                  <button
                    onClick={(e) => {
                      e.stopPropagation();
                      setOpenFilter(!openFilter);
                    }}
                    className="border relative border-[#E4E4E4] flex font-medium items-center font-poppins px-2 py-1 rounded-[8px] gap-[8px]"
                  >
                    Filter
                    <img src={Chevron} alt="" />
                    {openFilter && (
                      <div className="filter-component absolute -left-[5rem] bg-white p-[16px] rounded-[8px] shadow-xl border border-[#D8D8D8]">
                        <div className="filter-row">
                          <p className="filter-label font-poppins font-normal text-[0.875rem]">
                            Filter by: Month
                          </p>
                          <div className="filter-control">
                            <button
                              onClick={(e) => {
                                e.stopPropagation();
                                handleMonthChange("prev");
                              }}
                            >
                              {"<"}
                            </button>
                            {displayedMonths.map((month, index) => (
                              <label key={month}>
                                <input
                                  type="radio"
                                  name="month"
                                  value={month}
                                  checked={
                                    months[startMonthIndex + index] ===
                                    months[selectedMonthIndex]
                                  }
                                  onChange={() => {
                                    setFilterByResults("filter");
                                    setSelectedMonthIndex(
                                      startMonthIndex + index
                                    );
                                  }}
                                />
                                {month}
                              </label>
                            ))}
                            <button
                              onClick={(e) => {
                                e.stopPropagation();
                                handleMonthChange("next");
                              }}
                            >
                              {">"}
                            </button>
                          </div>
                        </div>
                        <div className="filter-row">
                          <span className="filter-label font-poppins font-normal text-[0.875rem]">
                            Filter by: Year
                          </span>
                          <div className="filter-control">
                            <button
                              onClick={(e) => {
                                e.stopPropagation();
                                handleYearChange("prev");
                              }}
                            >
                              {"<"}
                            </button>
                            {displayedYears.map((year, index) => (
                              <label key={year}>
                                <input
                                  type="radio"
                                  name="year"
                                  value={year}
                                  checked={
                                    years[startYearIndex + index] ===
                                    years[selectedYearIndex]
                                  }
                                  onChange={() => {
                                    setFilterByResults("filter");
                                    setSelectedYearIndex(
                                      startYearIndex + index
                                    );
                                  }}
                                />
                                {year}
                              </label>
                            ))}
                            <button
                              onClick={(e) => {
                                e.stopPropagation();
                                handleYearChange("next");
                              }}
                            >
                              {">"}
                            </button>
                          </div>
                        </div>
                      </div>
                    )}
                  </button>
                  <ReactPaginate
                    breakLabel="..."
                    nextLabel=">"
                    nextClassName="font-bold text-black"
                    previousClassName="font-bold text-black"
                    onPageChange={handlePageClickResults}
                    pageRangeDisplayed={2}
                    pageCount={pageCountresults}
                    previousLabel="<"
                    // renderOnZeroPageCount={null}
                    marginPagesDisplayed={1}
                    activeClassName={
                      "bg-[#3EB211] rounded-full w-[24px] h-[20px] text-white flex items-center justify-center"
                    }
                    className="flex gap-4 text-[16px] items-center text-[#62646A99] font-poppins"
                  />
                </div>
              </div>
              {loadingResults ? (
                <div className="w-[100%] h-[50vh] flex items-center justify-center">
                  <Triangle
                    visible={true}
                    height="80"
                    width="80"
                    color="#3EB211"
                    ariaLabel="triangle-loading"
                    wrapperStyle={{}}
                    wrapperClass=""
                  />
                </div>
              ) : (
                <QuizResultsTable data={currentItemsresults} />
              )}
            </div>
            <div className="lg:hidden">
              <div className="flex">
                {/* <button
                  onClick={() => setAddQuiz(true)}
                  className="ml-auto bg-[#3EB211] text-white font-poppins px-[32px] py-[12px] font-semibold rounded-[6px] mb-[24px]"
                >
                  Add Quiz
                </button> */}
              </div>
              <div className="flex items-center justify-between">
                <h1 className="font-poppins text-[#333333] font-medium text-[1.125rem]">
                  Quiz Results List
                </h1>

                <CSVLink data={CSVdataresults} filename={"quizzes_data.csv"}>
                  <button className="text-[#3EB211] font-poppins font-medium">
                    Export CSV
                  </button>
                </CSVLink>
              </div>
              <div className="flex flex-col items-center gap-10 text-[0.875rem] mt-[24px]">
                <div className="flex items-center justify-between">
                  <div className="flex items-center w-[50%] border-b border-b-[#1D1D1D] px-2 pb-2">
                    <img src={Search} alt="" />
                    <input
                      onChange={(e) => setFilterByResults(e.target.value)}
                      type="text"
                      placeholder="Search"
                      className="px-3 outline-none font-poppins"
                    />
                  </div>
                  <button
                    onClick={(e) => {
                      e.stopPropagation();
                      setOpenFilter(!openFilter);
                    }}
                    className="border relative border-[#E4E4E4] flex font-medium items-center font-poppins px-2 py-1 rounded-[8px] gap-[8px]"
                  >
                    Filter
                    <img src={Chevron} alt="" />
                    {openFilter && (
                      <div className="filter-component absolute lg:-left-[5rem] -left-[13rem] bg-white p-[16px] rounded-[8px] shadow-xl border border-[#D8D8D8]">
                        <div className="filter-row">
                          <p className="filter-label font-poppins font-normal text-[0.875rem]">
                            Filter by: Month
                          </p>
                          <div className="filter-control">
                            <button
                              onClick={(e) => {
                                e.stopPropagation();
                                handleMonthChange("prev");
                              }}
                            >
                              {"<"}
                            </button>
                            {displayedMonths.map((month, index) => (
                              <label key={month}>
                                <input
                                  type="radio"
                                  name="month"
                                  value={month}
                                  checked={
                                    months[startMonthIndex + index] ===
                                    months[selectedMonthIndex]
                                  }
                                  onChange={() => {
                                    setFilterByResults("filter");
                                    setSelectedMonthIndex(
                                      startMonthIndex + index
                                    );
                                  }}
                                />
                                {month}
                              </label>
                            ))}
                            <button
                              onClick={(e) => {
                                e.stopPropagation();
                                handleMonthChange("next");
                              }}
                            >
                              {">"}
                            </button>
                          </div>
                        </div>
                        <div className="filter-row">
                          <span className="filter-label font-poppins font-normal text-[0.875rem]">
                            Filter by: Year
                          </span>
                          <div className="filter-control">
                            <button
                              onClick={(e) => {
                                e.stopPropagation();
                                handleYearChange("prev");
                              }}
                            >
                              {"<"}
                            </button>
                            {displayedYears.map((year, index) => (
                              <label key={year}>
                                <input
                                  type="radio"
                                  name="year"
                                  value={year}
                                  checked={
                                    years[startYearIndex + index] ===
                                    years[selectedYearIndex]
                                  }
                                  onChange={() => {
                                    setFilterByResults("filter");
                                    setSelectedYearIndex(
                                      startYearIndex + index
                                    );
                                  }}
                                />
                                {year}
                              </label>
                            ))}
                            <button
                              onClick={(e) => {
                                e.stopPropagation();
                                handleYearChange("next");
                              }}
                            >
                              {">"}
                            </button>
                          </div>
                        </div>
                      </div>
                    )}
                  </button>
                </div>
                <ReactPaginate
                  breakLabel="..."
                  nextLabel=">"
                  nextClassName="font-bold text-black"
                  previousClassName="font-bold text-black"
                  onPageChange={handlePageClickResults}
                  pageRangeDisplayed={5}
                  pageCount={pageCountresults}
                  previousLabel="<"
                  // renderOnZeroPageCount={null}
                  marginPagesDisplayed={1}
                  activeClassName={
                    "bg-[#3EB211] rounded-full w-[24px] h-[20px] text-white flex items-center justify-center"
                  }
                  className="flex gap-4 text-[16px] items-center text-[#62646A99] font-poppins"
                />
              </div>
              {loadingResults ? (
                <div className="w-[100%] h-[50vh] flex items-center justify-center">
                  <Triangle
                    visible={true}
                    height="80"
                    width="80"
                    color="#3EB211"
                    ariaLabel="triangle-loading"
                    wrapperStyle={{}}
                    wrapperClass=""
                  />
                </div>
              ) : (
                <QuizResultsTable data={currentItemsresults} />
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default Quizzes;
