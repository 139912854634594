import React, { useEffect, useState } from "react";
import Topbar from "../../components/new-design/Topbar";
import Sidebar from "../../components/new-design/Sidebar";
import { Link, useSearchParams } from "react-router-dom";
import NotifTable from "../../components/new-design/Tables/NotifTable";
import ReactPaginate from "react-paginate";
import { CSVLink } from "react-csv";
import { Triangle } from "react-loader-spinner";
import Search from "../../assets/new-design/flowbite_search-outline.svg";
import Chevron from "../../assets/new-design/ep_arrow-up-bold.svg";
import axios from "axios";
import { read, utils } from "xlsx";
import { useDropzone } from "react-dropzone";
import SendingDialog from "../../components/SendingDialog";
import UploadIcon from "../../assets/new-design/mingcute_upload-fill.svg";

function Notification() {
  const [searchParams] = useSearchParams();

  const [filterBy, setFilterBy] = useState("");

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  const [filteredData, setFilteredData] = useState([]);

  const [openFilter, setOpenFilter] = useState(false);

  const [notificationMessage, setNotificationMessage] = useState("");
  const [uploadedData, setUploadedData] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  // console.log(filteredUsers)

  function handleSend() {
    setIsOpen(true);
    if (notificationMessage !== "" && uploadedData.length > 0) {
      const newArray = uploadedData.map(({ id, Email }) => {
        const notif = notificationMessage;
        return { userEmail: Email, message: notif };
      });

      console.log(newArray);

      axios
        .post(
          "https://hero-backend-july10-72654a654b93.herokuapp.com/api/notification/notifications",
          newArray
        )
        .then((res) => {
          console.log(res);
          setIsOpen(false);
          if (res.status === 200) {
            setIsOpen(false);
            alert("Notification sent...");
            window.location.reload();
          } else {
            alert("Error sending notification...");
          }
          window.location.reload();
          setNotificationMessage("");
        });
    } else {
      alert("Make sure all fields have been filled out.");
      window.location.reload();
    }
  }

  const onDrop = (acceptedFiles) => {
    const reader = new FileReader();

    reader.onload = (event) => {
      const binaryData = event.target.result;
      const workbook = read(binaryData, { type: "binary" });

      const worksheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[worksheetName];

      const jsonData = utils.sheet_to_json(worksheet, { header: 1 });
      const headers = jsonData[0];
      const data = jsonData.filter((dat) => dat.length > 1).slice(1);
      const dataArray = data.map((row) => {
        const obj = {};
        headers.forEach((header, index) => {
          obj[header] = row[index];
        });
        return obj;
      });
      setUploadedData(dataArray);
      // console.log(dataArray); // Pass the data to the parent component
    };

    reader.readAsBinaryString(acceptedFiles[0]);
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  const CSVdata = data;

  const [itemOffset, setItemOffset] = useState(0);

  //   Number of items displayed on the table
  const itemsPerPage = 10;

  const endOffset = itemOffset + itemsPerPage;
  const currentItems =
    filteredData?.length > 0 && filterBy !== ""
      ? filteredData?.slice(itemOffset, endOffset)
      : filterBy === ""
      ? data?.slice(itemOffset, endOffset)
      : [];
  const pageCount =
    filteredData?.length > 0 && filterBy !== ""
      ? Math.ceil(filteredData?.length / itemsPerPage)
      : filterBy === ""
      ? Math.ceil(data?.length / itemsPerPage)
      : Math.ceil([].length / itemsPerPage);

  useEffect(() => {
    const getLearnings = async () => {
      setLoading(true);
      try {
        const res = await axios.get(
          "https://hero-backend-july10-72654a654b93.herokuapp.com/api/notification/notifications"
        );
        console.log(res.data);
        setLoading(false);
        const groupedData = res.data.reduce((acc, curr) => {
          const date = curr.createdAt.split("T")[0];
          const key = `${curr.message}_${date}`;

          if (acc[key]) {
            acc[key].emails.add(curr.userEmail);
          } else {
            acc[key] = {
              emails: new Set([curr.userEmail]),
              date: date,
              message: curr.message,
            };
          }

          return acc;
        }, {});

        // Convert the grouped object into an array and convert Set to array
        const result = Object.values(groupedData).map((group) => ({
          emails: [...group.emails],
          date: group.date,
          message: group.message,
        }));

        // console.log(result);
        setData(result.reverse());
      } catch (error) {
        console.log(error);
      }
    };
    getLearnings();
  }, []);

  useEffect(() => {
    if (filterBy === "filter") {
      const filteredResults = data?.filter((i) => i);
      console.log(filteredResults);
      setFilteredData(filteredResults);
    } else {
      const filteredResults = data?.filter((i) => i.message.includes(filterBy));
      console.log(filteredResults);
      setFilteredData(filteredResults);
    }
  }, [data, filterBy]);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % data.length;
    console.log(
      `User requested page number ${event.selected}, which is offset ${newOffset}`
    );
    setItemOffset(newOffset);
  };

  return (
    <div className="flex h-[100vh]">
      <Sidebar />
      <div className="lg:w-[82%] w-[100%]">
        <Topbar />
        {!searchParams.get("type") && (
          <div className="lg:p-[32px] p-[16px] h-[88vh] overflow-y-scroll">
            <div className="border border-[#E4E4E4] rounded-[8px] py-[24px] hidden lg:block">
              <div className="flex pr-[16px]">
                <Link to="/notification?type=send" className="ml-auto">
                  <button className=" bg-[#3EB211] text-white font-poppins px-[32px] py-[12px] font-semibold rounded-[6px] mb-[24px]">
                    Send Notification
                  </button>
                </Link>
              </div>
              <div className="flex justify-between px-[16px]">
                <h1 className="font-poppins text-[#333333] font-medium text-[1.125rem]">
                  Notification List
                </h1>
                <div className="flex items-center gap-10 text-[0.875rem]">
                  <CSVLink data={CSVdata} filename={"notifications_data.csv"}>
                    <button className="text-[#3EB211] font-poppins font-medium">
                      Export CSV
                    </button>
                  </CSVLink>
                  <div className="flex items-center border-b border-b-[#1D1D1D] px-2 pb-2">
                    <img src={Search} alt="" />
                    <input
                      onChange={(e) => setFilterBy(e.target.value)}
                      type="text"
                      placeholder="Search"
                      className="px-3 outline-none font-poppins"
                    />
                  </div>
                  <button
                    onClick={(e) => {
                      e.stopPropagation();
                      setOpenFilter(!openFilter);
                    }}
                    className="border relative border-[#E4E4E4] flex font-medium items-center font-poppins px-2 py-1 rounded-[8px] gap-[8px]"
                  >
                    Filter
                    <img src={Chevron} alt="" />
                  </button>
                  <ReactPaginate
                    breakLabel="..."
                    nextLabel=">"
                    nextClassName="font-bold text-black"
                    previousClassName="font-bold text-black"
                    onPageChange={handlePageClick}
                    pageRangeDisplayed={2}
                    pageCount={pageCount}
                    previousLabel="<"
                    // renderOnZeroPageCount={null}
                    marginPagesDisplayed={1}
                    activeClassName={
                      "bg-[#3EB211] rounded-full w-[24px] h-[20px] text-white flex items-center justify-center"
                    }
                    className="flex gap-4 text-[16px] items-center text-[#62646A99] font-poppins"
                  />
                </div>
              </div>
              {loading ? (
                <div className="w-[100%] h-[50vh] flex items-center justify-center">
                  <Triangle
                    visible={true}
                    height="80"
                    width="80"
                    color="#3EB211"
                    ariaLabel="triangle-loading"
                    wrapperStyle={{}}
                    wrapperClass=""
                  />
                </div>
              ) : (
                <NotifTable data={currentItems} />
              )}
            </div>
            <div className="lg:hidden">
              <div className="flex">
                <button className="ml-auto bg-[#3EB211] text-white font-poppins px-[32px] py-[12px] font-semibold rounded-[6px] mb-[24px]">
                  Send Notification
                </button>
              </div>
              <div className="flex items-center justify-between">
                <h1 className="font-poppins text-[#333333] font-medium text-[1.125rem]">
                  Notification List
                </h1>

                <CSVLink data={CSVdata} filename={"notifications_data.csv"}>
                  <button className="text-[#3EB211] font-poppins font-medium">
                    Export CSV
                  </button>
                </CSVLink>
              </div>
              <div className="flex flex-col items-center gap-10 text-[0.875rem] mt-[24px]">
                <div className="flex items-center justify-between">
                  <div className="flex items-center w-[50%] border-b border-b-[#1D1D1D] px-2 pb-2">
                    <img src={Search} alt="" />
                    <input
                      onChange={(e) => setFilterBy(e.target.value)}
                      type="text"
                      placeholder="Search"
                      className="px-3 outline-none font-poppins"
                    />
                  </div>
                  <button
                    onClick={(e) => {
                      e.stopPropagation();
                      setOpenFilter(!openFilter);
                    }}
                    className="border relative border-[#E4E4E4] flex font-medium items-center font-poppins px-2 py-1 rounded-[8px] gap-[8px]"
                  >
                    Filter
                    <img src={Chevron} alt="" />
                  </button>
                </div>
                <ReactPaginate
                  breakLabel="..."
                  nextLabel=">"
                  nextClassName="font-bold text-black"
                  previousClassName="font-bold text-black"
                  onPageChange={handlePageClick}
                  pageRangeDisplayed={5}
                  pageCount={pageCount}
                  previousLabel="<"
                  // renderOnZeroPageCount={null}
                  marginPagesDisplayed={1}
                  activeClassName={
                    "bg-[#3EB211] rounded-full w-[24px] h-[20px] text-white flex items-center justify-center"
                  }
                  className="flex gap-4 text-[16px] items-center text-[#62646A99] font-poppins"
                />
              </div>
              {loading ? (
                <div className="w-[100%] h-[100vh] flex items-center justify-center">
                  <Triangle
                    visible={true}
                    height="80"
                    width="80"
                    color="#3EB211"
                    ariaLabel="triangle-loading"
                    wrapperStyle={{}}
                    wrapperClass=""
                  />
                </div>
              ) : (
                <NotifTable data={currentItems} />
              )}
            </div>
          </div>
        )}

        {searchParams.get("type") && (
          <div className="lg:p-[32px] p-[16px] h-[88vh] overflow-y-scroll font-poppins">
            <div className="">
              <h1 className="mb-5 text-[1.125rem] font-medium text-center text-[#333333]">
                Send Notification
              </h1>

              {uploadedData.length < 1 && (
                <div
                  className="border p-6 border-[#C8C8C8] rounded-[6px]"
                  {...getRootProps()}
                >
                  <input {...getInputProps()} />
                  {isDragActive ? (
                    <p>Drop the Excel file here...</p>
                  ) : (
                    <div className="bg-[#F1F1F1] rounded-[6px] border border-[#C8C8C8] py-4 cursor-pointer flex items-center justify-center gap-2">
                      <img src={UploadIcon} alt="" />
                      <p className="font-medium lg:text-[0.875] text-[0.75rem]">
                        Choose an excel file{" "}
                        <span className="font-normal">or drag file here</span>
                      </p>
                    </div>
                  )}
                </div>
              )}

              {uploadedData.length > 0 && (
                <div className="border p-6 border-[#C8C8C8] rounded-[6px]">
                  <h4 className="font-medium text-[#62646A99] text-[0.75rem] mb-2">
                    EMAILS
                  </h4>
                  <div className="flex items-center gap-3 flex-wrap">
                    {uploadedData.map((data, index) => (
                      <p
                        className="p-1 rounded-[6px] lg:text-[0.875] text-[0.75rem] bg-[#F1F1F1] w-max font-medium text-[#333333]"
                        key={data.Email}
                      >
                        {data.Email}
                      </p>
                    ))}
                  </div>
                </div>
              )}

              {uploadedData.length > 0 && (
                <div className="mt-10">
                  <p className="font-medium text-[#62646A99] text-[0.75rem] mb-2">
                    Notification Message
                  </p>
                  <input
                    className="px-3 py-1.5 border border-[#C8C8C8] outline-none w-[100%] mb-4 rounded-[6px] font-poppins text-[0.875rem]"
                    onChange={(e) => setNotificationMessage(e.target.value)}
                    placeholder="Eg. New Learning Video Available"
                    type="text"
                  />
                  <div className="flex items-center justify-between">
                    <button
                      className="border outline-none bg-[#F1F1F1] text-[##333333] font-poppins font-semibold text-lg py-3 px-8 rounded-[6px]"
                      onClick={() => window.location.reload()}
                    >
                      Cancel
                    </button>
                    <button
                      className="border outline-none bg-[#359602] text-white font-poppins font-semibold text-lg py-3 px-8 rounded-[6px]"
                      onClick={handleSend}
                    >
                      Send
                    </button>
                  </div>
                </div>
              )}
              <SendingDialog isOpen={isOpen} setIsOpen={setIsOpen} />
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default Notification;
