import React, { useEffect, useState } from "react";
import Sidebar from "../../components/new-design/Sidebar";
import Topbar from "../../components/new-design/Topbar";
import Search from "../../assets/new-design/flowbite_search-outline.svg";
import Chevron from "../../assets/new-design/ep_arrow-up-bold.svg";
import axios from "axios";
import { Triangle } from "react-loader-spinner";
import ReactPaginate from "react-paginate";
import { CSVLink } from "react-csv";
import LearningTable from "../../components/new-design/Tables/LearningTable";

function Learning() {
  const [users, setUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [filterBy, setFilterBy] = useState("");
  const [loading, setLoading] = useState(false);
  const [addLearning, setAddLearning] = useState(false);

  const CSVdata = users;

  const [itemOffset, setItemOffset] = useState(0);

  //   Number of items displayed on the table
  const itemsPerPage = 10;

  const endOffset = itemOffset + itemsPerPage;
  const currentItems =
    filteredUsers?.length > 0 && filterBy !== ""
      ? filteredUsers?.slice(itemOffset, endOffset)
      : filterBy === ""
      ? users?.slice(itemOffset, endOffset)
      : [];
  const pageCount =
    filteredUsers?.length > 0 && filterBy !== ""
      ? Math.ceil(filteredUsers?.length / itemsPerPage)
      : filterBy === ""
      ? Math.ceil(users?.length / itemsPerPage)
      : Math.ceil([].length / itemsPerPage);

  useEffect(() => {
    const getLearnings = async () => {
      setLoading(true);
      try {
        const res = await axios.get(
          "https://hero-backend-july10-72654a654b93.herokuapp.com/api/learning/learnings"
        );
        console.log(res.data);
        setLoading(false);
        setUsers(res.data);
      } catch (error) {
        console.log(error);
      }
    };
    getLearnings();
  }, []);

  useEffect(() => {
    const filteredResults = users.filter((i) =>
      i.title.toLowerCase().includes(filterBy.toLowerCase())
    );
    setFilteredUsers(filteredResults);
  }, [users, filterBy]);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % users.length;
    console.log(
      `User requested page number ${event.selected}, which is offset ${newOffset}`
    );
    setItemOffset(newOffset);
  };
  return (
    <div className="flex h-[100vh]">
      <Sidebar />
      <div className="w-[100%] lg:w-[82%]">
        <Topbar />
        <div className="lg:p-[32px] p-[16px] h-[88vh] overflow-y-scroll">
          <div className="border border-[#E4E4E4] rounded-[8px] py-[24px] hidden lg:block">
            <div className="flex pr-[16px]">
              <button
                onClick={() => setAddLearning(true)}
                className="ml-auto bg-[#3EB211] text-white font-poppins px-[32px] py-[12px] font-semibold rounded-[6px] mb-[24px]"
              >
                Add Learning
              </button>
            </div>
            <div className="flex justify-between px-[16px]">
              <h1 className="font-poppins text-[#333333] font-medium text-[1.125rem]">
                Learning List
              </h1>
              <div className="flex items-center gap-10 text-[0.875rem]">
                <CSVLink data={CSVdata} filename={"learning_videos_data.csv"}>
                  <button className="text-[#3EB211] font-poppins font-medium">
                    Export CSV
                  </button>
                </CSVLink>
                <div className="flex items-center border-b border-b-[#1D1D1D] px-2 pb-2">
                  <img src={Search} alt="" />
                  <input
                    onChange={(e) => setFilterBy(e.target.value)}
                    type="text"
                    placeholder="Search"
                    className="px-3 outline-none font-poppins"
                  />
                </div>
                <button className="border border-[#E4E4E4] flex font-medium items-center font-poppins px-2 py-1 rounded-[8px] gap-[8px]">
                  Filter
                  <img src={Chevron} alt="" />
                </button>
                <ReactPaginate
                  breakLabel="..."
                  nextLabel=">"
                  nextClassName="font-bold text-black"
                  previousClassName="font-bold text-black"
                  onPageChange={handlePageClick}
                  pageRangeDisplayed={2}
                  pageCount={pageCount}
                  previousLabel="<"
                  // renderOnZeroPageCount={null}
                  marginPagesDisplayed={1}
                  activeClassName={
                    "bg-[#3EB211] rounded-full w-[24px] h-[20px] text-white flex items-center justify-center"
                  }
                  className="flex gap-4 text-[16px] items-center text-[#62646A99] font-poppins"
                />
              </div>
            </div>
            {loading ? (
              <div className="w-[100%] h-[50vh] flex items-center justify-center">
                <Triangle
                  visible={true}
                  height="80"
                  width="80"
                  color="#3EB211"
                  ariaLabel="triangle-loading"
                  wrapperStyle={{}}
                  wrapperClass=""
                />
              </div>
            ) : (
              <LearningTable
                data={currentItems}
                addLearning={addLearning}
                setAddLearning={setAddLearning}
              />
            )}
          </div>

          <div className="lg:hidden">
            <div className="flex pr-[16px]">
              <button
                onClick={() => setAddLearning(true)}
                className="ml-auto bg-[#3EB211] text-white font-poppins px-[32px] py-[12px] font-semibold rounded-[6px] mb-[24px]"
              >
                Add Learning
              </button>
            </div>
            <div className="flex items-center justify-between">
              <h1 className="font-poppins text-[#333333] font-medium text-[1.125rem]">
                Learning List
              </h1>

              <CSVLink data={CSVdata} filename={"learning_videos_data.csv"}>
                <button className="text-[#3EB211] font-poppins font-medium">
                  Export CSV
                </button>
              </CSVLink>
            </div>
            <div className="flex flex-col items-center gap-10 text-[0.875rem] mt-[24px]">
              <div className="flex items-center justify-between">
                <div className="flex items-center w-[50%] border-b border-b-[#1D1D1D] px-2 pb-2">
                  <img src={Search} alt="" />
                  <input
                    onChange={(e) => setFilterBy(e.target.value)}
                    type="text"
                    placeholder="Search"
                    className="px-3 outline-none font-poppins"
                  />
                </div>
                <button className="border border-[#E4E4E4] flex font-medium items-center font-poppins px-2 py-1 rounded-[8px] gap-[8px]">
                  Filter
                  <img src={Chevron} alt="" />
                </button>
              </div>
              <ReactPaginate
                breakLabel="..."
                nextLabel=">"
                nextClassName="font-bold text-black"
                previousClassName="font-bold text-black"
                onPageChange={handlePageClick}
                pageRangeDisplayed={5}
                pageCount={pageCount}
                previousLabel="<"
                // renderOnZeroPageCount={null}
                marginPagesDisplayed={1}
                activeClassName={
                  "bg-[#3EB211] rounded-full w-[24px] h-[20px] text-white flex items-center justify-center"
                }
                className="flex gap-4 text-[16px] items-center text-[#62646A99] font-poppins"
              />
            </div>
            {loading ? (
              <div className="w-[100%] h-[50vh] flex items-center justify-center">
                <Triangle
                  visible={true}
                  height="80"
                  width="80"
                  color="#3EB211"
                  ariaLabel="triangle-loading"
                  wrapperStyle={{}}
                  wrapperClass=""
                />
              </div>
            ) : (
              <LearningTable
                data={currentItems}
                addLearning={addLearning}
                setAddLearning={setAddLearning}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Learning;
