import React, { useEffect, useState } from "react";
import Sorting from "../../../assets/new-design/f7_sort-up.svg";
import More from "../../../assets/new-design/uiw_more.svg";
import { Dialog } from "@headlessui/react";
import { CSVLink } from "react-csv";
import Close from "../../../assets/new-design/ic_round-close.svg";
import axios from "axios";

function QuizTable({ data, addQuiz, setAddQuiz }) {
  const [isOpen, setIsOpen] = useState(false);
  const [sure, setSure] = useState(false);
  const [selected, setSelected] = useState();
  const CSVdata = selected ?? [];
  const [loading, setLoading] = useState(false);

  console.log(selected);
  console.log(isOpen);
  console.log(addQuiz);

  const [quiz, setQuiz] = useState({
    title: "",
    year: "",
    month: "",
    question: "",
    answer: "",
  });

  const [options, setOptions] = useState([]);

  // useEffect(() => {
  //   setIsOpen(addQuiz);
  // }, [addQuiz]);

  useEffect(() => {
    console.log(JSON.parse(localStorage.getItem("selected_quiz")));
    setQuiz({
      title: JSON.parse(localStorage.getItem("selected_quiz"))?.title || "",
      year: JSON.parse(localStorage.getItem("selected_quiz"))?.year || "",
      month: JSON.parse(localStorage.getItem("selected_quiz"))?.month || "",
      question:
        JSON.parse(localStorage.getItem("selected_quiz"))?.question || "",
      answer: JSON.parse(localStorage.getItem("selected_quiz"))?.answer || "",
    });
    setOptions(
      JSON.parse(localStorage.getItem("selected_quiz"))?.choices || ""
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localStorage.getItem("selected_quiz")]);

  function handleChoices(e) {
    setOptions(e.target.value.split(","));
  }

  const handleSubmit = async () => {
    // e.preventDefault();
    // setIsOpen(true);
    setLoading(true);
    const title = quiz.title;
    const year = quiz.year;
    const month = quiz.month;
    const question = quiz.question;
    const answer = quiz.answer;
    const choices = options;

    const post = { title, year, month, question, answer, choices };
    console.log(post);

    try {
      localStorage.getItem("selected_quiz")
        ? await axios.put(
            `https://hero-backend-july10-72654a654b93.herokuapp.com/api/quiz/quizzes/${selected._id}`,
            post
          )
        : await axios.post(
            "https://hero-backend-july10-72654a654b93.herokuapp.com/api/quiz",
            post
          );
      setLoading(false);
      // setIsOpen(false);
      alert("Quiz has been created successfully");
      window.location.reload();
      // selected ? navigate(`/quiz/${selected._id}`) : window.location.reload();
    } catch (error) {
      alert("Please make sure all fields have been filled out...");
    }
  };

  return (
    <div className="w-[100%] mt-[24px]">
      <table className="w-[100%] hidden lg:block">
        <thead className="bg-[#F1F1F1]">
          <tr>
            <td className="pl-[16px] py-[8px] text-[#62646A99] font-poppins lg:w-[12%]">
              {" "}
              <div className="flex items-center gap-[8px]">
                Title
                <img src={Sorting} alt="" />
              </div>
            </td>
            <td className="py-[8px] text-[#62646A99] font-poppins lg:w-[12%]">
              {" "}
              <div className="flex items-center justify-center gap-[8px]">
                Year
                <img src={Sorting} alt="" />
              </div>
            </td>
            <td className="py-[8px] text-[#62646A99] font-poppins lg:w-[12%]">
              {" "}
              <div className="flex items-center gap-[8px]">
                Month
                <img src={Sorting} alt="" />
              </div>
            </td>
            <td className="py-[8px] text-[#62646A99] font-poppins lg:w-[52%]">
              {" "}
              <div className="flex items-center justify-center gap-[8px]">
                Question
                <img src={Sorting} alt="" />
              </div>
            </td>
            <td className="py-[8px] text-[#62646A99] font-poppins lg:w-[12%]"></td>
          </tr>
        </thead>
        <tbody>
          {data?.map((da) => (
            <tr key={da._id}>
              <td className="pl-[16px] py-[16px] text-[#333333] font-medium font-poppins">
                {da.title}
              </td>
              <td className="py-[16px] text-[#333333] font-medium font-poppins text-center">
                {da.year}
              </td>
              <td className="py-[16px] text-[#333333] font-medium font-poppins">
                {da.month}
              </td>
              <td className="py-[16px] text-[#333333] text-center font-medium font-poppins">
                {da.question}
              </td>
              <td className="text-[#333333] font-medium font-poppins">
                <div
                  onClick={() => {
                    setSelected(da);
                    setIsOpen(true);
                  }}
                  className="py-[16px] flex items-center justify-center cursor-pointer"
                >
                  <img src={More} alt="" />
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <div className="lg:hidden flex flex-col gap-[16px] w-[100%]">
        {data.map((da) => (
          <div
            key={da._id}
            className="flex flex-col border border-[#C8C8C8] rounded-lg p-4 space-y-6 font-poppins"
          >
            <div
              onClick={() => {
                setSelected(da);
                setIsOpen(true);
              }}
              className="flex justify-end cursor-pointer"
            >
              <img src={More} alt="" />
            </div>
            <div className="flex items-center justify-between flex-wrap gap-6">
              <div>
                <p className="text-[0.75rem] text-[#62646A99] mb-1">TITLE</p>
                <p className="text-[0.875rem] text-[#333333] font-medium">
                  {da.title}
                </p>
              </div>
              <div>
                <p className="text-[0.75rem] text-[#62646A99] mb-1">YEAR</p>
                <p className="text-[0.875rem] text-[#333333] font-medium">
                  {da.year}
                </p>
              </div>
            </div>
            <div className="flex items-center justify-between flex-wrap gap-6">
              <div>
                <p className="text-[0.75rem] text-[#62646A99] mb-1">QUESTION</p>
                <p className="text-[0.875rem] text-[#333333] font-medium">
                  {" "}
                  {da.question}
                </p>
              </div>
              <div>
                <p className="text-[0.75rem] text-[#62646A99] mb-1">MONTH</p>
                <p className="text-[0.875rem] text-[#333333] font-medium">
                  {da.month}
                </p>
              </div>
            </div>
          </div>
        ))}
      </div>

      <Dialog
        open={isOpen}
        onClose={() => {
          localStorage.removeItem("selected_quiz");
          // setAddQuiz(false);
          setIsOpen(false);
          setSure(false);
        }}
        className="relative z-50"
      >
        <div
          className="fixed inset-0 flex w-screen items-center bg-black/70 justify-center p-4"
          onClick={(e) => e.stopPropagation()}
        >
          {!addQuiz && !sure && (
            <Dialog.Panel className="lg:space-y-6 border bg-white lg:p-5 p-2 rounded-[8px] lg:w-[620px] w-[100%] font-poppins">
              <div className="flex items-center justify-between">
                <CSVLink data={[CSVdata]} filename={"users_data.csv"}>
                  <button className="text-[#3EB211] font-poppins text-[0.875rem] font-medium">
                    Export CSV
                  </button>
                </CSVLink>
                <img
                  className="cursor-pointer"
                  onClick={() => {
                    // setAddQuiz(false);
                    setIsOpen(false);
                    setSure(false);
                  }}
                  src={Close}
                  alt=""
                />
              </div>
              <div className="flex flex-col items-center justify-center gap-[8px] border-b border-b-[#E4E4E4] lg:pb-[24px] pb-[12px]">
                <h1 className="text-center font-medium text-[1.125rem]">
                  Quiz Details
                </h1>
                <p className="text-center font-medium text-[0.875rem] text-[#62646A99]">
                  {selected?.title}
                </p>
              </div>
              <div className="border border-[#E4E4E4] rounded-[8px] lg:p-[24px] p-[6px] mt-[24px]">
                <div className="flex items-center justify-between mb-[24px]">
                  <div>
                    <h1 className="text-[#62646A99] text-[0.75rem] font-medium">
                      YEAR
                    </h1>
                    <p className="text-[0.875rem] text-[#333333] font-medium">
                      {selected?.year}
                    </p>
                  </div>
                  <div>
                    <h1 className="text-[#62646A99] text-[0.75rem] font-medium text-right">
                      MONTH
                    </h1>
                    <p className="text-[0.875rem] text-[#333333] font-medium text-right">
                      {selected?.month}
                    </p>
                  </div>
                </div>
                <div className="flex items-center justify-between mb-[24px] flex-wrap gap-6">
                  <div>
                    <h1 className="text-[#62646A99] text-[0.75rem] font-medium">
                      QUESTION
                    </h1>
                    <p className="text-[0.875rem] text-[#333333] font-medium lg:w-[300px]">
                      {selected?.question}
                    </p>
                  </div>
                  <div>
                    <h1 className="text-[#62646A99] text-[0.75rem] font-medium lg:text-right">
                      ANSWER
                    </h1>
                    <p className="text-[0.875rem] text-[#333333] font-medium lg:text-right">
                      {selected?.answer}
                    </p>
                  </div>
                </div>
                <div className="grid grid-cols-2 gap-y-[24px]">
                  {selected?.choices.map((cho, index) => (
                    <div key={index}>
                      <h1
                        className={`text-[#62646A99] text-[0.75rem] font-medium ${
                          index % 2 !== 0 && "text-right"
                        }`}
                      >
                        CHOICE {index + 1}
                      </h1>
                      <p
                        className={`text-[0.875rem] text-[#333333] font-medium ${
                          index % 2 !== 0 && "text-right"
                        }`}
                      >
                        {cho}
                      </p>
                    </div>
                  ))}
                </div>
              </div>

              <button
                className="w-[100%] bg-[#3EB211] lg:mt-[24px] mt-[12px] text-white font-semibold text-[0.875rem] h-[44px] rounded-[6px]"
                onClick={() => {
                  localStorage.setItem(
                    "selected_quiz",
                    JSON.stringify(selected)
                  );
                  setSure(false);
                  setAddQuiz(true);
                }}
              >
                View
              </button>

              <button
                className="w-[100%] bg-[#B41007] lg:mt-[24px] mt-[12px] text-white font-semibold text-[0.875rem] h-[44px] rounded-[6px]"
                onClick={() => setSure(true)}
              >
                Delete
              </button>
            </Dialog.Panel>
          )}

          {!addQuiz && sure && (
            <Dialog.Panel className="space-y-6 border bg-white p-5 rounded-[8px] lg:w-[620px] font-poppins">
              <div className="flex flex-col items-center justify-center gap-[8px] border-b border-b-[#E4E4E4] pb-[24px]">
                <h1 className="text-center font-medium text-[0.875rem] text-[#333333]">
                  Are you sure you want to delete this quiz?
                </h1>
              </div>

              <div className="flex items-center gap-[16px] ">
                <button
                  className="w-[50%] bg-[#F1F1F1] py-[12px] mt-[24px] text-[#333333] font-semibold text-[0.875rem] h-[44px] rounded-[6px]"
                  onClick={() => {
                    setSure(false);
                    setIsOpen(false);
                  }}
                >
                  Cancel
                </button>

                <button
                  className="w-[50%] bg-[#B41007] mt-[24px] py-[12px] text-white font-semibold text-[0.875rem] h-[44px] rounded-[6px]"
                  onClick={() => {
                    setSure(false);
                    setIsOpen(false);
                  }}
                >
                  Delete
                </button>
              </div>
            </Dialog.Panel>
          )}
        </div>
      </Dialog>
      <Dialog
        open={addQuiz}
        onClose={() => {
          localStorage.removeItem("selected_quiz");
          // setAddQuiz(false);
        }}
        className="relative z-50"
      >
        <div
          className="fixed inset-0 flex w-screen items-center bg-black/70 justify-center p-4"
          onClick={(e) => e.stopPropagation()}
        >
          <Dialog.Panel
            className="space-y-4 border bg-white p-5 rounded-[8px] lg:w-[620px] font-poppins"
            onClick={(e) => e.stopPropagation()}
          >
            <div className="flex items-center justify-end mb-[24px]">
              <img
                className="cursor-pointer"
                onClick={() => {
                  setAddQuiz(false);
                  // setIsOpen(false);
                  // setSure(false);
                }}
                src={Close}
                alt=""
              />
            </div>
            <div className="flex flex-col items-center justify-center gap-[8px] border-b border-b-[#E4E4E4] pb-[24px]">
              <h1 className="text-center font-medium text-[1.125rem]">
                Quiz Details
              </h1>
            </div>
            <div className="border border-[#E4E4E4] rounded-[8px] p-[24px] mt-[24px] space-y-3">
              <div className="flex flex-col gap-2">
                <label
                  className="font-medium text-[0.75rem] text-[#62646A99]"
                  htmlFor=""
                >
                  Title
                </label>
                <input
                  className="border border-[#333333] outline-none rounded px-3 py-1.5 text-[0.75rem]"
                  type="text"
                  value={quiz.title}
                  onChange={(e) => setQuiz({ ...quiz, title: e.target.value })}
                />
              </div>
              <div className="flex flex-col gap-2">
                <label
                  className="font-medium text-[0.75rem] text-[#62646A99]"
                  htmlFor=""
                >
                  Year
                </label>
                <input
                  className="border border-[#333333] outline-none rounded px-3 py-1.5 text-[0.75rem]"
                  type="text"
                  value={quiz.year}
                  onChange={(e) => setQuiz({ ...quiz, year: e.target.value })}
                />
              </div>
              <div className="flex flex-col gap-2">
                <label
                  htmlFor=""
                  className="font-medium text-[0.75rem] text-[#62646A99]"
                >
                  Month
                </label>
                <select
                  name=""
                  id=""
                  value={quiz.month}
                  className="border border-[#333333] outline-none rounded px-3 py-1.5 text-[0.75rem]"
                  onChange={(e) => setQuiz({ ...quiz, month: e.target.value })}
                >
                  <option value="" disabled selected>
                    Choose Month
                  </option>
                  <option value="Jan">Jan</option>
                  <option value="Feb">Feb</option>
                  <option value="Mar">Mar</option>
                  <option value="Apr">Apr</option>
                  <option value="May">May</option>
                  <option value="Jun">Jun</option>
                  <option value="Jul">Jul</option>
                  <option value="Aug">Aug</option>
                  <option value="Sep">Sep</option>
                  <option value="Oct">Oct</option>
                  <option value="Nov">Nov</option>
                  <option value="Dec">Dec</option>
                </select>
              </div>
              <div className="flex flex-col gap-2">
                <label
                  className="font-medium text-[0.75rem] text-[#62646A99]"
                  htmlFor=""
                >
                  Question
                </label>
                <input
                  className="border border-[#333333] outline-none rounded px-3 py-1.5 text-[0.75rem]"
                  type="text"
                  value={quiz.question}
                  onChange={(e) =>
                    setQuiz({ ...quiz, question: e.target.value })
                  }
                />
              </div>
              <div className="flex flex-col gap-2">
                <label
                  className="font-medium text-[0.75rem] text-[#62646A99]"
                  htmlFor=""
                >
                  Answer
                </label>
                <input
                  className="border border-[#333333] outline-none rounded px-3 py-1.5 text-[0.75rem]"
                  type="text"
                  value={quiz.answer}
                  onChange={(e) => setQuiz({ ...quiz, answer: e.target.value })}
                />
              </div>
              <div className="flex flex-col gap-2">
                <label
                  className="font-medium text-[0.75rem] text-[#62646A99]"
                  htmlFor=""
                >
                  Choices
                </label>
                <input
                  className="border border-[#333333] outline-none rounded px-3 py-1.5 text-[0.75rem]"
                  type="text"
                  value={options.toString()}
                  onChange={(e) => handleChoices(e)}
                />
              </div>
            </div>

            <button
              className="w-[100%] bg-[#3EB211] mt-[24px] text-white font-semibold text-[0.875rem] h-[44px] rounded-[6px]"
              onClick={() => handleSubmit()}
            >
              {loading ? "Please wait..." : "Save"}
            </button>

            <button
              className="w-[100%] bg-[#F1F1F1] text-[#333333] mt-[24px font-semibold text-[0.875rem] h-[44px] rounded-[6px]"
              onClick={() => setIsOpen(false)}
            >
              Cancel
            </button>
          </Dialog.Panel>
        </div>
      </Dialog>
    </div>
  );
}

export default QuizTable;
