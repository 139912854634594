import React, { useEffect, useState } from "react";
import NotifBell from "../../assets/new-design/mingcute_notification-fill.svg";
import UserProfile from "../../assets/new-design/line-md_account.svg";
import Chevron from "../../assets/new-design/ep_arrow-up-bold.svg";
import MobileMenu from "../../assets/new-design/jam_menu.svg";
import { Dialog } from "@headlessui/react";
import DashboardBlack from "../../assets/new-design/mage_dashboard-chart-fill-black.svg";
import Dashboard from "../../assets/new-design/mage_dashboard-chart-fill.svg";
import UsersBlack from "../../assets/new-design/heroicons_user-20-solid-black.svg";
import Users from "../../assets/new-design/heroicons_user-20-solid.svg";
import QuizzesBlack from "../../assets/new-design/material-symbols_quiz-black.svg";
import Quizzes from "../../assets/new-design/material-symbols_quiz.svg";
import LearingBlack from "../../assets/new-design/ic_round-school-black.svg";
import Learing from "../../assets/new-design/ic_round-school.svg";
import BenefitsBlack from "../../assets/new-design/ion_gift-black.svg";
import Benefits from "../../assets/new-design/ion_gift.svg";
import AdvocacyBlack from "../../assets/new-design/mage_megaphone-a-fill-black.svg";
import Advocacy from "../../assets/new-design/mage_megaphone-a-fill.svg";
import ActivitiesBlack from "../../assets/new-design/icon-park-solid_activity-source-black.svg";
import Activities from "../../assets/new-design/icon-park-solid_activity-source.svg";
import NotificationBlack from "../../assets/new-design/mage_notification-bell-question-mark-fill (1).svg";
import Notification from "../../assets/new-design/mage_notification-bell-question-mark-fill.svg";
import GiftBlack from "../../assets/new-design/IonGift (2).svg";
import Gift from "../../assets/new-design/IonGift (1).svg";
import WalletBlack from "../../assets/new-design/IonWallet (2).svg";
import Wallet from "../../assets/new-design/IonWallet.svg";
import { Link } from "react-router-dom";

function Topbar() {
  const [isOpen, setIsOpen] = useState(false);
  const [isAnimating, setIsAnimating] = useState(false);

  useEffect(() => {
    if (isOpen) {
      setIsAnimating(true);
    }
  }, [isOpen]);

  const [open, setOpen] = useState("");
  const navs = [
    {
      id: 1,
      title: "Dashboard",
      logo_black: DashboardBlack,
      logo: Dashboard,
      link: "/home",
    },
    {
      id: 2,
      title: "Users",
      logo_black: UsersBlack,
      logo: Users,
      link: "/users",
    },
    {
      id: 3,
      title: "Quizzes",
      logo_black: QuizzesBlack,
      logo: Quizzes,
      link: "/quiz",
      sub: [
        {
          id: 1,
          title: "All Quizzes",
          link: "/quiz?type=all",
        },
        {
          id: 2,
          title: "Quiz Results",
          link: "/quiz?type=results",
        },
      ],
    },
    {
      id: 4,
      title: "Learning",
      logo_black: LearingBlack,
      logo: Learing,
      link: "/learning",
    },
    {
      id: 5,
      title: "Benefits",
      logo_black: BenefitsBlack,
      logo: Benefits,
      link: "/benefit",
    },
    {
      id: 6,
      title: "Advocacy",
      logo_black: AdvocacyBlack,
      logo: Advocacy,
      link: "/advocacy",
    },
    {
      id: 7,
      title: "Activities",
      logo_black: ActivitiesBlack,
      logo: Activities,
      link: "/activity",
      sub: [
        {
          id: 1,
          title: "All Activity",
          link: "/activity?type=all",
        },
        {
          id: 2,
          title: "Proof of Activity",
          link: "/activity?type=proof",
        },
      ],
    },
    {
      id: 8,
      title: "Notification",
      logo_black: NotificationBlack,
      logo: Notification,
      link: "/notification",
    },
    {
      id: 9,
      title: "Rewards",
      logo_black: GiftBlack,
      logo: Gift,
      link: "/rewards",
      sub: [
        {
          id: 1,
          title: "Hero Stipend",
          link: "/rewards?type=stipend",
        },
        {
          id: 2,
          title: "FT9ja Merch",
          link: "/rewards?type=merch",
        },
        {
          id: 2,
          title: "Gifts & Offers",
          link: "/rewards?type=gifts",
        },
      ],
    },
    {
      id: 10,
      title: "Withdrawals",
      logo_black: WalletBlack,
      logo: Wallet,
      link: "/withdrawal",
      sub: [
        {
          id: 1,
          title: "Bank Transfer",
          link: "/withdrawal?type=bank",
        },
        {
          id: 2,
          title: "Paypal",
          link: "/withdrawal?type=paypal",
        },
      ],
    },
  ];

  return (
    <div>
      <div className="flex items-center justify-between lg:hidden px-[16px] py-[3px] mb-[19px]">
        <img
          className="w-[90px] h-[40px]"
          src="https://res.cloudinary.com/ddlupbcws/image/upload/q_auto/v1722239085/WhatsApp_Image_2024-07-25_at_13.59.59_ed4f944b_bg_removed_1_swebkl.webp"
          alt=""
        />
        <img onClick={() => setIsOpen(true)} src={MobileMenu} alt="" />
      </div>
      <div className="flex items-center justify-between px-[32px] py-[18px] border-y border-y-[#E4E4E4]">
        <h1 className="text-[2rem] font-semibold font-poppins">
          {window.location.pathname === "/users"
            ? "Users"
            : window.location.pathname === "/quiz"
            ? "Quizzes"
            : window.location.pathname === "/learning"
            ? "Learning"
            : window.location.pathname === "/benefit"
            ? "Benefits"
            : window.location.pathname === "/advocacy"
            ? "Advocacy"
            : window.location.pathname === "/activity"
            ? "Activity"
            : window.location.pathname === "/notification"
            ? "Notification"
            : window.location.pathname === "/rewards"
            ? "Rewards"
            : window.location.pathname === "/withdrawal"
            ? "Withdrawal Requests"
            : "Dashboard"}
        </h1>
        <div className="flex items-center gap-[32px]">
          <div className="bg-[#F1F1F1] p-[4px] rounded-[4px]">
            <img src={NotifBell} alt="" />
          </div>
          <div className="flex items-center gap-[8px]">
            <img src={UserProfile} alt="" />
            <p className="font-poppins hidden lg:block">Owen Yakubu</p>
            <img src={Chevron} alt="" />
          </div>
        </div>
      </div>
      <Dialog
        open={isOpen}
        onClose={() => setIsOpen(false)}
        className="relative z-50"
      >
        <div className="fixed inset-0 flex w-screen items-start bg-black/30 justify-end">
          <Dialog.Panel
            className={`border bg-white p-5 rounded-l-[8px] font-poppins transform transition-transform duration-700 ${
              isAnimating ? "translate-y-full" : "translate-y-3"
            }`}
            onTransitionEnd={() => setIsAnimating(false)}
          >
            {/* Your content here */}
            <div className="w-[100%] px-[32px] py-[5px]">
              <img
                className="w-[90px] h-[40px]"
                src="https://res.cloudinary.com/ddlupbcws/image/upload/q_auto/v1722239085/WhatsApp_Image_2024-07-25_at_13.59.59_ed4f944b_bg_removed_1_swebkl.webp"
                alt=""
              />
              <div className="mt-[16px] flex flex-col">
                {navs.map((nav) => {
                  return nav.title !== "Quizzes" &&
                    nav.title !== "Activities" &&
                    nav.title !== "Withdrawals" &&
                    nav.title !== "Rewards" ? (
                    <Link
                      to={nav.link}
                      key={nav.id}
                      className="flex items-center gap-[8px] py-[10px] relative"
                    >
                      <img
                        src={
                          nav.link === window.location.pathname
                            ? nav.logo
                            : nav.logo_black
                        }
                        alt=""
                      />
                      <p
                        className={`${
                          nav.link === window.location.pathname
                            ? "text-[#3EB211]"
                            : "text-[#333333]"
                        } font-medium font-poppins`}
                      >
                        {nav.title}
                      </p>
                      {nav.link === window.location.pathname && (
                        <div className="bg-[#3EB211] w-[11px] h-[53px] absolute -left-[3.3rem] rounded-tr-[56px] rounded-br-[56px]"></div>
                      )}
                    </Link>
                  ) : (
                    <div
                      key={nav.id}
                      onClick={() => {
                        // window.location.href = `${nav.link}`;
                        if (open === nav.title) {
                          setOpen("");
                        } else {
                          setOpen(nav.title);
                        }
                      }}
                    >
                      <div className="flex items-center gap-[8px] py-[10px] justify-between relative cursor-pointer">
                        <div className="flex items-center gap-[8px]">
                          <img
                            src={
                              nav.link === window.location.pathname
                                ? nav.logo
                                : nav.logo_black
                            }
                            alt=""
                          />
                          <p
                            className={`${
                              nav.link === window.location.pathname
                                ? "text-[#3EB211]"
                                : "text-[#333333]"
                            } font-medium font-poppins`}
                          >
                            {nav.title}
                          </p>
                        </div>
                        <img src={Chevron} alt="" />
                        {nav.link === window.location.pathname && (
                          <div className="bg-[#3EB211] w-[11px] h-[53px] absolute -left-[3.3rem] rounded-tr-[56px] rounded-br-[56px]"></div>
                        )}
                      </div>
                      {open === nav.title && (
                        <div className="pl-7 flex flex-col">
                          {nav.sub.map((su) => (
                            <Link
                              onClick={() => setIsOpen(false)}
                              to={su.link}
                              key={su.id}
                            >
                              <p className="py-[7px]">{su.title}</p>
                            </Link>
                          ))}
                        </div>
                      )}
                    </div>
                  );
                })}
              </div>
            </div>
          </Dialog.Panel>
        </div>
      </Dialog>
    </div>
  );
}

export default Topbar;
