import React, { useEffect, useState } from "react";
import Sidebar from "../../components/new-design/Sidebar";
import Topbar from "../../components/new-design/Topbar";
import Search from "../../assets/new-design/flowbite_search-outline.svg";
import Chevron from "../../assets/new-design/ep_arrow-up-bold.svg";
import axios from "axios";
import { Triangle } from "react-loader-spinner";
import ReactPaginate from "react-paginate";
import { CSVLink } from "react-csv";
import { useSearchParams } from "react-router-dom";
import StipendTable from "../../components/new-design/Tables/StipendTable";
import MerchTable from "../../components/new-design/Tables/MerchTable";
import GiftsOffersTable from "../../components/new-design/Tables/GiftsOffersTable";

function Rewards() {
  const [searchParams] = useSearchParams();
  const [users, setUsers] = useState([]);
  const [gifts, setGifts] = useState([]);
  const [merch, setMerch] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [filterBy, setFilterBy] = useState("");
  const [loading, setLoading] = useState(false);
  const [filteredGifts, setFilteredGifts] = useState([]);
  const [filterByGifts, setFilterByGifts] = useState("");
  const [loadingGifts, setLoadingGifts] = useState(false);
  const [filteredMerch, setFilteredMerch] = useState([]);
  const [filterByMerch, setFilterByMerch] = useState("");
  const [loadingMerch, setLoadingMerch] = useState(false);

  const CSVdata = users;

  const [itemOffset, setItemOffset] = useState(0);

  //   Number of items displayed on the table
  const itemsPerPage = 10;

  const endOffset = itemOffset + itemsPerPage;
  const currentItems =
    filteredUsers?.length > 0 && filterBy !== ""
      ? filteredUsers?.slice(itemOffset, endOffset)
      : filterBy === ""
      ? users?.slice(itemOffset, endOffset)
      : [];
  const pageCount =
    filteredUsers?.length > 0 && filterBy !== ""
      ? Math.ceil(filteredUsers?.length / itemsPerPage)
      : filterBy === ""
      ? Math.ceil(users?.length / itemsPerPage)
      : Math.ceil([].length / itemsPerPage);

  useEffect(() => {
    const filteredResults = users.filter((i) => i);
    setFilteredUsers(filteredResults);
  }, [users, filterBy]);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % users.length;
    console.log(
      `User requested page number ${event.selected}, which is offset ${newOffset}`
    );
    setItemOffset(newOffset);
  };

  const CSVdataMerch = merch;

  const [itemOffsetMerch, setItemOffsetMerch] = useState(0);

  //   Number of items displayed on the table
  const itemsPerPageMerch = 10;

  const endOffsetMerch = itemOffsetMerch + itemsPerPageMerch;
  const currentItemsMerch =
    filteredMerch?.length > 0 && filterByMerch !== ""
      ? filteredMerch?.slice(itemOffsetMerch, endOffsetMerch)
      : filterByMerch === ""
      ? merch?.slice(itemOffsetMerch, endOffsetMerch)
      : [];
  const pageCountMerch =
    filteredMerch?.length > 0 && filterByMerch !== ""
      ? Math.ceil(filteredMerch?.length / itemsPerPageMerch)
      : filterByMerch === ""
      ? Math.ceil(merch?.length / itemsPerPageMerch)
      : Math.ceil([].length / itemsPerPageMerch);

  useEffect(() => {
    const filteredResults = merch.filter(
      (i) =>
        i.name.toLowerCase().includes(filterByMerch) ||
        i.method.toLowerCase().includes(filterByMerch) ||
        i.userEmail.toLowerCase().includes(filterByMerch)
    );
    setFilteredMerch(filteredResults);
  }, [merch, filterByMerch]);

  const handlePageClickMerch = (event) => {
    const newOffset = (event.selected * itemsPerPageMerch) % merch.length;
    console.log(
      `User requested page number ${event.selected}, which is offset ${newOffset}`
    );
    setItemOffsetMerch(newOffset);
  };

  const CSVdataGift = gifts;

  const [itemOffsetGift, setItemOffsetGift] = useState(0);

  //   Number of items displayed on the table
  const itemsPerPageGift = 10;

  const endOffsetGift = itemOffsetGift + itemsPerPageGift;
  const currentItemsGift =
    filteredGifts?.length > 0 && filterByGifts !== ""
      ? filteredGifts?.slice(itemOffsetGift, endOffsetGift)
      : filterByGifts === ""
      ? gifts?.slice(itemOffsetGift, endOffsetGift)
      : [];
  const pageCountGift =
    filteredGifts?.length > 0 && filterByGifts !== ""
      ? Math.ceil(filteredGifts?.length / itemsPerPageGift)
      : filterByGifts === ""
      ? Math.ceil(gifts?.length / itemsPerPageGift)
      : Math.ceil([].length / itemsPerPageGift);

  useEffect(() => {
    const filteredResults = gifts.filter(
      (i) =>
        i.name.toLowerCase().includes(filterByGifts) ||
        i.method.toLowerCase().includes(filterByGifts) ||
        i.userEmail.toLowerCase().includes(filterByGifts)
    );
    setFilteredGifts(filteredResults);
  }, [gifts, filterByGifts]);

  const handlePageClickGift = (event) => {
    const newOffset = (event.selected * itemsPerPageGift) % gifts.length;
    console.log(
      `User requested page number ${event.selected}, which is offset ${newOffset}`
    );
    setItemOffsetGift(newOffset);
  };

  useEffect(() => {
    setLoading(true);
    setLoadingGifts(true);
    setLoadingMerch(true);

    axios
      .get(
        "https://hero-backend-july10-72654a654b93.herokuapp.com/api/hero-stipend-benefit/hero-stipends-rewards/"
      )
      .then((res) => {
        console.log(res.data);
        setUsers(res.data.reverse());
        setLoading(false);

        // const all = res.data;
      });

    axios
      .get(
        "https://hero-backend-july10-72654a654b93.herokuapp.com/api/ft9ja-merch-benefit/ft9ja-merch-benefits-rewards"
      )
      .then((res) => {
        console.log(res.data);

        setLoadingMerch(false);
        setMerch(res.data.reverse());
        // const all = res.data;
      });

    axios
      .get(
        "https://hero-backend-july10-72654a654b93.herokuapp.com/api/gifts-offers-benefit/gift-and-offers-benefits-rewards"
      )
      .then((res) => {
        console.log(res.data);
        setLoadingGifts(false);
        setGifts(res.data.reverse());
        // const all = res.data;
      });
  }, []);

  return (
    <div className="flex h-[100vh]">
      <Sidebar />
      <div className="w-[100%] lg:w-[82%]">
        <Topbar />
        {searchParams.get("type") === "stipend" && (
          <div className="lg:p-[32px] p-[16px] h-[88vh] overflow-y-scroll">
            <div className="border border-[#E4E4E4] rounded-[8px] py-[24px] hidden lg:block">
              <div className="flex justify-between px-[16px]">
                <h1 className="font-poppins text-[#333333] font-medium text-[1.125rem]">
                  Rewards List (Hero Stipend)
                </h1>
                <div className="flex items-center gap-10 text-[0.875rem]">
                  <CSVLink data={CSVdata} filename={"hero_stipend_data.csv"}>
                    <button className="text-[#3EB211] font-poppins font-medium">
                      Export CSV
                    </button>
                  </CSVLink>
                  <div className="flex items-center border-b border-b-[#1D1D1D] px-2 pb-2">
                    <img src={Search} alt="" />
                    <input
                      onChange={(e) => setFilterBy(e.target.value)}
                      type="text"
                      placeholder="Search"
                      className="px-3 outline-none font-poppins"
                    />
                  </div>
                  <button className="border border-[#E4E4E4] flex font-medium items-center font-poppins px-2 py-1 rounded-[8px] gap-[8px]">
                    Filter
                    <img src={Chevron} alt="" />
                  </button>
                  <ReactPaginate
                    breakLabel="..."
                    nextLabel=">"
                    nextClassName="font-bold text-black"
                    previousClassName="font-bold text-black"
                    onPageChange={handlePageClick}
                    pageRangeDisplayed={2}
                    pageCount={pageCount}
                    previousLabel="<"
                    // renderOnZeroPageCount={null}
                    marginPagesDisplayed={1}
                    activeClassName={
                      "bg-[#3EB211] rounded-full w-[24px] h-[20px] text-white flex items-center justify-center"
                    }
                    className="flex gap-4 text-[16px] items-center text-[#62646A99] font-poppins"
                  />
                </div>
              </div>
              {loading ? (
                <div className="w-[100%] h-[50vh] flex items-center justify-center">
                  <Triangle
                    visible={true}
                    height="80"
                    width="80"
                    color="#3EB211"
                    ariaLabel="triangle-loading"
                    wrapperStyle={{}}
                    wrapperClass=""
                  />
                </div>
              ) : (
                <StipendTable data={currentItems} />
              )}
            </div>

            <div className="lg:hidden">
              <div className="flex items-center justify-between">
                <h1 className="font-poppins text-[#333333] font-medium text-[1.125rem]">
                  Rewards List (Hero Stipend)
                </h1>

                <CSVLink data={CSVdata} filename={"hero_stipend_data.csv"}>
                  <button className="text-[#3EB211] font-poppins font-medium">
                    Export CSV
                  </button>
                </CSVLink>
              </div>
              <div className="flex flex-col items-center gap-10 text-[0.875rem] mt-[24px]">
                <div className="flex items-center justify-between">
                  <div className="flex items-center w-[50%] border-b border-b-[#1D1D1D] px-2 pb-2">
                    <img src={Search} alt="" />
                    <input
                      onChange={(e) => setFilterBy(e.target.value)}
                      type="text"
                      placeholder="Search"
                      className="px-3 outline-none font-poppins"
                    />
                  </div>
                  <button className="border border-[#E4E4E4] flex font-medium items-center font-poppins px-2 py-1 rounded-[8px] gap-[8px]">
                    Filter
                    <img src={Chevron} alt="" />
                  </button>
                </div>
                <ReactPaginate
                  breakLabel="..."
                  nextLabel=">"
                  nextClassName="font-bold text-black"
                  previousClassName="font-bold text-black"
                  onPageChange={handlePageClick}
                  pageRangeDisplayed={5}
                  pageCount={pageCount}
                  previousLabel="<"
                  // renderOnZeroPageCount={null}
                  marginPagesDisplayed={1}
                  activeClassName={
                    "bg-[#3EB211] rounded-full w-[24px] h-[20px] text-white flex items-center justify-center"
                  }
                  className="flex gap-4 text-[16px] items-center text-[#62646A99] font-poppins"
                />
              </div>
              {loading ? (
                <div className="w-[100%] h-[50vh] flex items-center justify-center">
                  <Triangle
                    visible={true}
                    height="80"
                    width="80"
                    color="#3EB211"
                    ariaLabel="triangle-loading"
                    wrapperStyle={{}}
                    wrapperClass=""
                  />
                </div>
              ) : (
                <StipendTable data={currentItems} />
              )}
            </div>
          </div>
        )}
        {searchParams.get("type") === "merch" && (
          <div className="lg:p-[32px] p-[16px] h-[88vh] overflow-y-scroll">
            <div className="border border-[#E4E4E4] rounded-[8px] py-[24px] hidden lg:block">
              <div className="flex justify-between px-[16px]">
                <h1 className="font-poppins text-[#333333] font-medium text-[1.125rem]">
                  Rewards List (FT9ja Merch)
                </h1>
                <div className="flex items-center gap-10 text-[0.875rem]">
                  <CSVLink
                    data={CSVdataMerch}
                    filename={"ft9ja_merch_data.csv"}
                  >
                    <button className="text-[#3EB211] font-poppins font-medium">
                      Export CSV
                    </button>
                  </CSVLink>
                  <div className="flex items-center border-b border-b-[#1D1D1D] px-2 pb-2">
                    <img src={Search} alt="" />
                    <input
                      onChange={(e) => setFilterByMerch(e.target.value)}
                      type="text"
                      placeholder="Search"
                      className="px-3 outline-none font-poppins"
                    />
                  </div>
                  <button className="border border-[#E4E4E4] flex font-medium items-center font-poppins px-2 py-1 rounded-[8px] gap-[8px]">
                    Filter
                    <img src={Chevron} alt="" />
                  </button>
                  <ReactPaginate
                    breakLabel="..."
                    nextLabel=">"
                    nextClassName="font-bold text-black"
                    previousClassName="font-bold text-black"
                    onPageChange={handlePageClickMerch}
                    pageRangeDisplayed={2}
                    pageCount={pageCountMerch}
                    previousLabel="<"
                    // renderOnZeroPageCount={null}
                    marginPagesDisplayed={1}
                    activeClassName={
                      "bg-[#3EB211] rounded-full w-[24px] h-[20px] text-white flex items-center justify-center"
                    }
                    className="flex gap-4 text-[16px] items-center text-[#62646A99] font-poppins"
                  />
                </div>
              </div>
              {loadingMerch ? (
                <div className="w-[100%] h-[50vh] flex items-center justify-center">
                  <Triangle
                    visible={true}
                    height="80"
                    width="80"
                    color="#3EB211"
                    ariaLabel="triangle-loading"
                    wrapperStyle={{}}
                    wrapperClass=""
                  />
                </div>
              ) : (
                <MerchTable data={currentItemsMerch} />
              )}
            </div>

            <div className="lg:hidden">
              <div className="flex items-center justify-between">
                <h1 className="font-poppins text-[#333333] font-medium text-[1.125rem]">
                  Rewards List (FT9ja Merch)
                </h1>

                <CSVLink data={CSVdataMerch} filename={"ft9ja_merch_data.csv"}>
                  <button className="text-[#3EB211] font-poppins font-medium">
                    Export CSV
                  </button>
                </CSVLink>
              </div>
              <div className="flex flex-col items-center gap-10 text-[0.875rem] mt-[24px]">
                <div className="flex items-center justify-between">
                  <div className="flex items-center w-[50%] border-b border-b-[#1D1D1D] px-2 pb-2">
                    <img src={Search} alt="" />
                    <input
                      onChange={(e) => setFilterByMerch(e.target.value)}
                      type="text"
                      placeholder="Search"
                      className="px-3 outline-none font-poppins"
                    />
                  </div>
                  <button className="border border-[#E4E4E4] flex font-medium items-center font-poppins px-2 py-1 rounded-[8px] gap-[8px]">
                    Filter
                    <img src={Chevron} alt="" />
                  </button>
                </div>
                <ReactPaginate
                  breakLabel="..."
                  nextLabel=">"
                  nextClassName="font-bold text-black"
                  previousClassName="font-bold text-black"
                  onPageChange={handlePageClickMerch}
                  pageRangeDisplayed={5}
                  pageCount={pageCountMerch}
                  previousLabel="<"
                  // renderOnZeroPageCount={null}
                  marginPagesDisplayed={1}
                  activeClassName={
                    "bg-[#3EB211] rounded-full w-[24px] h-[20px] text-white flex items-center justify-center"
                  }
                  className="flex gap-4 text-[16px] items-center text-[#62646A99] font-poppins"
                />
              </div>
              {loadingMerch ? (
                <div className="w-[100%] h-[50vh] flex items-center justify-center">
                  <Triangle
                    visible={true}
                    height="80"
                    width="80"
                    color="#3EB211"
                    ariaLabel="triangle-loading"
                    wrapperStyle={{}}
                    wrapperClass=""
                  />
                </div>
              ) : (
                <MerchTable data={currentItemsMerch} />
              )}
            </div>
          </div>
        )}
        {searchParams.get("type") === "gifts" && (
          <div className="lg:p-[32px] p-[16px] h-[88vh] overflow-y-scroll">
            <div className="border border-[#E4E4E4] rounded-[8px] py-[24px] hidden lg:block">
              <div className="flex justify-between px-[16px]">
                <h1 className="font-poppins text-[#333333] font-medium text-[1.125rem]">
                  Rewards List (Gifts & Offers)
                </h1>
                <div className="flex items-center gap-10 text-[0.875rem]">
                  <CSVLink
                    data={CSVdataGift}
                    filename={"gifts_&_offers_data.csv"}
                  >
                    <button className="text-[#3EB211] font-poppins font-medium">
                      Export CSV
                    </button>
                  </CSVLink>
                  <div className="flex items-center border-b border-b-[#1D1D1D] px-2 pb-2">
                    <img src={Search} alt="" />
                    <input
                      onChange={(e) => setFilterByGifts(e.target.value)}
                      type="text"
                      placeholder="Search"
                      className="px-3 outline-none font-poppins"
                    />
                  </div>
                  <button className="border border-[#E4E4E4] flex font-medium items-center font-poppins px-2 py-1 rounded-[8px] gap-[8px]">
                    Filter
                    <img src={Chevron} alt="" />
                  </button>
                  <ReactPaginate
                    breakLabel="..."
                    nextLabel=">"
                    nextClassName="font-bold text-black"
                    previousClassName="font-bold text-black"
                    onPageChange={handlePageClickGift}
                    pageRangeDisplayed={2}
                    pageCount={pageCountGift}
                    previousLabel="<"
                    // renderOnZeroPageCount={null}
                    marginPagesDisplayed={1}
                    activeClassName={
                      "bg-[#3EB211] rounded-full w-[24px] h-[20px] text-white flex items-center justify-center"
                    }
                    className="flex gap-4 text-[16px] items-center text-[#62646A99] font-poppins"
                  />
                </div>
              </div>
              {loadingGifts ? (
                <div className="w-[100%] h-[50vh] flex items-center justify-center">
                  <Triangle
                    visible={true}
                    height="80"
                    width="80"
                    color="#3EB211"
                    ariaLabel="triangle-loading"
                    wrapperStyle={{}}
                    wrapperClass=""
                  />
                </div>
              ) : (
                <GiftsOffersTable data={currentItemsGift} />
              )}
            </div>

            <div className="lg:hidden">
              <div className="flex items-center justify-between">
                <h1 className="font-poppins text-[#333333] font-medium text-[1.125rem]">
                  Rewards List (Gifts & Offers)
                </h1>

                <CSVLink
                  data={CSVdataGift}
                  filename={"gifts_&_offers_data.csv"}
                >
                  <button className="text-[#3EB211] font-poppins font-medium">
                    Export CSV
                  </button>
                </CSVLink>
              </div>
              <div className="flex flex-col items-center gap-10 text-[0.875rem] mt-[24px]">
                <div className="flex items-center justify-between">
                  <div className="flex items-center w-[50%] border-b border-b-[#1D1D1D] px-2 pb-2">
                    <img src={Search} alt="" />
                    <input
                      onChange={(e) => setFilterByGifts(e.target.value)}
                      type="text"
                      placeholder="Search"
                      className="px-3 outline-none font-poppins"
                    />
                  </div>
                  <button className="border border-[#E4E4E4] flex font-medium items-center font-poppins px-2 py-1 rounded-[8px] gap-[8px]">
                    Filter
                    <img src={Chevron} alt="" />
                  </button>
                </div>
                <ReactPaginate
                  breakLabel="..."
                  nextLabel=">"
                  nextClassName="font-bold text-black"
                  previousClassName="font-bold text-black"
                  onPageChange={handlePageClickGift}
                  pageRangeDisplayed={5}
                  pageCount={pageCountGift}
                  previousLabel="<"
                  // renderOnZeroPageCount={null}
                  marginPagesDisplayed={1}
                  activeClassName={
                    "bg-[#3EB211] rounded-full w-[24px] h-[20px] text-white flex items-center justify-center"
                  }
                  className="flex gap-4 text-[16px] items-center text-[#62646A99] font-poppins"
                />
              </div>
              {loadingGifts ? (
                <div className="w-[100%] h-[50vh] flex items-center justify-center">
                  <Triangle
                    visible={true}
                    height="80"
                    width="80"
                    color="#3EB211"
                    ariaLabel="triangle-loading"
                    wrapperStyle={{}}
                    wrapperClass=""
                  />
                </div>
              ) : (
                <GiftsOffersTable data={currentItemsGift} />
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default Rewards;
