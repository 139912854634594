import React, { useState } from "react";
import Sorting from "../../../assets/new-design/f7_sort-up.svg";
// import More from "../../../assets/new-design/uiw_more.svg";
import { Dialog } from "@headlessui/react";
// import { CSVLink } from "react-csv";
import Close from "../../../assets/new-design/ic_round-close.svg";

function convertDate(isoDate) {
  //   const isoDate = "2023-06-23T20:37:03.658Z";

  // Create a new Date object from the ISO string
  const date = new Date(isoDate);

  // Extract day, month, and year
  const day = String(date.getDate()).padStart(2, "0");
  const month = date.toLocaleString("default", { month: "long" });
  const year = date.getFullYear();

  // Format the date as "dd mm, yyyy"
  const formattedDate = `${day} ${month}, ${year}`;

  return formattedDate; // Output: 23 June, 2023
}

function QuizResultsTable({ data }) {
  const [isOpen, setIsOpen] = useState(false);

  //   const [selected, setSelected] = useState();
  //   console.log(selected);
  console.log(data);
  //   const CSVdata = selected ?? [];

  return (
    <div className="w-[100%] mt-[24px]">
      <table className="w-[100%] hidden lg:block">
        <thead className="bg-[#F1F1F1]">
          <tr>
            <td className="pl-[16px] py-[8px] text-[#62646A99] font-poppins lg:w-[14.3%]">
              {" "}
              <div className="flex items-center gap-[8px]">
                User
                <img src={Sorting} alt="" />
              </div>
            </td>
            <td className="py-[8px] text-[#62646A99] font-poppins lg:w-[14.3%]">
              {" "}
              <div className="flex items-center justify-center gap-[8px]">
                Title
                <img src={Sorting} alt="" />
              </div>
            </td>
            <td className="py-[8px] text-[#62646A99] font-poppins lg:w-[14.3%]">
              {" "}
              <div className="flex items-center justify-center gap-[8px]">
                Score
                <img src={Sorting} alt="" />
              </div>
            </td>
            <td className="py-[8px] text-[#62646A99] font-poppins lg:w-[14.3%]">
              {" "}
              <div className="flex items-center justify-center gap-[8px]">
                Attempts
                <img src={Sorting} alt="" />
              </div>
            </td>
            <td className="py-[8px] text-[#62646A99] font-poppins lg:w-[14.3%]">
              {" "}
              <div className="flex items-center justify-center gap-[8px]">
                Quiz Month
                <img src={Sorting} alt="" />
              </div>
            </td>
            <td className="py-[8px] text-[#62646A99] font-poppins lg:w-[14.3%]">
              {" "}
              <div className="flex items-center justify-center gap-[8px]">
                Quiz Year
                <img src={Sorting} alt="" />
              </div>
            </td>
            <td className="py-[8px] text-[#62646A99] font-poppins lg:w-[14.3%]">
              {" "}
              <div className="flex items-center justify-center gap-[8px]">
                Date
                <img src={Sorting} alt="" />
              </div>
            </td>
            {/* <td className="py-[8px] text-[#62646A99] font-poppins lg:w-[14.3%]">Action</td> */}
          </tr>
        </thead>
        <tbody>
          {data?.map((da) => (
            <tr key={da._id}>
              <td className="pl-[16px] py-[16px] text-[#333333] font-medium font-poppins">
                {da.userEmail}
              </td>
              <td className="py-[16px] text-[#333333] font-medium font-poppins text-center">
                {da.quizTitle}
              </td>
              <td className="py-[16px] text-[#333333] font-medium font-poppins text-center">
                {da.score}
              </td>
              <td className="py-[16px] text-[#333333] text-center font-medium font-poppins">
                {da.attempts}
              </td>
              <td className="py-[16px] text-[#333333] text-center font-medium font-poppins">
                {da.quizMonth}
              </td>
              <td className="py-[16px] text-[#333333] text-center font-medium font-poppins">
                {da.quizYear}
              </td>
              <td className="py-[16px] text-[#333333] text-center font-medium font-poppins">
                {convertDate(da.createdAt)}
              </td>
              {/* <td className="text-[#333333] font-medium font-poppins">
                <div
                  onClick={() => {
                    setSelected(da);
                    setIsOpen(true);
                  }}
                  className="py-[16px] flex items-center justify-center cursor-pointer"
                >
                  <img src={More} alt="" />
                </div>
              </td> */}
            </tr>
          ))}
        </tbody>
      </table>

      <div className="lg:hidden flex flex-col gap-[16px] w-[100%]">
        {data.map((da) => (
          <div
            key={da._id}
            className="flex flex-col border border-[#C8C8C8] rounded-lg p-4 space-y-6 font-poppins"
          >
            {/* <div
              onClick={() => {
                setSelected(da);
                setIsOpen(true);
              }}
              className="flex justify-end cursor-pointer"
            >
              <img src={More} alt="" />
            </div> */}
            <div className="flex items-center justify-between flex-wrap gap-6">
              <div>
                <p className="text-[0.75rem] text-[#62646A99] mb-1">TITLE</p>
                <p className="text-[0.875rem] text-[#333333] font-medium">
                  {da.quizTitle}
                </p>
              </div>
              <div>
                <p className="text-[0.75rem] text-[#62646A99] mb-1">YEAR</p>
                <p className="text-[0.875rem] text-[#333333] font-medium">
                  {da.quizYear}
                </p>
              </div>
            </div>
            <div className="flex items-center justify-between flex-wrap gap-6">
              <div>
                <p className="text-[0.75rem] text-[#62646A99] mb-1">EMAIL</p>
                <p className="text-[0.875rem] text-[#333333] font-medium">
                  {" "}
                  {da.userEmail}
                </p>
              </div>
              <div>
                <p className="text-[0.75rem] text-[#62646A99] mb-1">MONTH</p>
                <p className="text-[0.875rem] text-[#333333] font-medium">
                  {da.quizMonth}
                </p>
              </div>
            </div>
            <div className="flex items-center justify-between flex-wrap gap-6">
              <div>
                <p className="text-[0.75rem] text-[#62646A99] mb-1">SCORE</p>
                <p className="text-[0.875rem] text-[#333333] font-medium">
                  {da.score}
                </p>
              </div>
              <div>
                <p className="text-[0.75rem] text-[#62646A99] mb-1">ATTEMPTS</p>
                <p className="text-[0.875rem] text-[#333333] font-medium text-right">
                  {da.attempts}
                </p>
              </div>
            </div>
            <div className="flex items-center justify-between">
              <div>
                <p className="text-[0.75rem] text-[#62646A99] mb-1">Date</p>
                <p className="text-[0.875rem] text-[#333333] font-medium">
                  {convertDate(da.createdAt)}
                </p>
              </div>
            </div>
          </div>
        ))}
      </div>

      <Dialog
        open={isOpen}
        onClose={() => {
          setIsOpen(false);
        }}
        className="relative z-50"
      >
        <div className="fixed inset-0 flex w-screen items-center bg-black/70 justify-center p-4">
          <Dialog.Panel className="space-y-6 border bg-white p-5 rounded-[8px] lg:w-[620px] font-poppins">
            <div className="flex items-center justify-between mb-[24px]">
              {/* <CSVLink data={[CSVdata]} filename={"users_data.csv"}>
                <button className="text-[#3EB211] font-poppins text-[0.875rem] font-medium">
                  Export CSV
                </button>
              </CSVLink> */}
              <img
                className="cursor-pointer"
                onClick={() => {
                  setIsOpen(false);
                }}
                src={Close}
                alt=""
              />
            </div>
            <div className="flex flex-col items-center justify-center gap-[8px] border-b border-b-[#E4E4E4] pb-[24px]">
              <h1 className="text-center font-medium text-[1.125rem]">
                Quiz Details
              </h1>
              {/* <p className="text-center font-medium text-[0.875rem] text-[#62646A99]">
                {selected?.title}
              </p> */}
            </div>
            <div className="border border-[#E4E4E4] rounded-[8px] p-[24px] mt-[24px]">
              <div className="flex items-center justify-between mb-[24px]">
                <div>
                  <h1 className="text-[#62646A99] text-[0.75rem] font-medium">
                    YEAR
                  </h1>
                  {/* <p className="text-[0.875rem] text-[#333333] font-medium">
                    {selected?.year}
                  </p> */}
                </div>
                <div>
                  <h1 className="text-[#62646A99] text-[0.75rem] font-medium text-right">
                    MONTH
                  </h1>
                  {/* <p className="text-[0.875rem] text-[#333333] font-medium text-right">
                    {selected?.month}
                  </p> */}
                </div>
              </div>
              <div className="flex items-center justify-between mb-[24px]">
                <div>
                  <h1 className="text-[#62646A99] text-[0.75rem] font-medium">
                    QUESTION
                  </h1>
                  {/* <p className="text-[0.875rem] text-[#333333] font-medium w-[300px]">
                    {selected?.question}
                  </p> */}
                </div>
                <div>
                  <h1 className="text-[#62646A99] text-[0.75rem] font-medium text-right">
                    ANSWER
                  </h1>
                  {/* <p className="text-[0.875rem] text-[#333333] font-medium text-right">
                    {selected?.answer}
                  </p> */}
                </div>
              </div>
              {/* <div className="grid grid-cols-2 gap-y-[24px]">
                {selected?.choices.map((cho, index) => (
                  <div key={index}>
                    <h1
                      className={`text-[#62646A99] text-[0.75rem] font-medium ${
                        index % 2 !== 0 && "text-right"
                      }`}
                    >
                      CHOICE {index + 1}
                    </h1>
                    <p
                      className={`text-[0.875rem] text-[#333333] font-medium ${
                        index % 2 !== 0 && "text-right"
                      }`}
                    >
                      {cho}
                    </p>
                  </div>
                ))}
              </div> */}
            </div>

            <button
              className="w-[100%] bg-[#3EB211] mt-[24px] text-white font-semibold text-[0.875rem] h-[44px] rounded-[6px]"
              onClick={() => setIsOpen(false)}
            >
              View
            </button>

            <button
              className="w-[100%] bg-[#B41007] mt-[24px] text-white font-semibold text-[0.875rem] h-[44px] rounded-[6px]"
              onClick={() => setIsOpen(false)}
            >
              Delete
            </button>
          </Dialog.Panel>
        </div>
      </Dialog>
    </div>
  );
}

export default QuizResultsTable;
