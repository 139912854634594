import React, { useState } from "react";
import Sorting from "../../../assets/new-design/f7_sort-up.svg";
import More from "../../../assets/new-design/uiw_more.svg";
import { Dialog } from "@headlessui/react";
import { CSVLink } from "react-csv";
import Close from "../../../assets/new-design/ic_round-close.svg";

function NotifTable({ data }) {
  const [isOpen, setIsOpen] = useState(false);

  const [selected, setSelected] = useState();
  console.log(selected);
  console.log(data);
  const CSVdata = selected ?? [];

  return (
    <div className="w-[100%] mt-[24px]">
      <table className="w-[100%] hidden lg:table">
        <thead className="bg-[#F1F1F1]">
          <tr>
            <td className="pl-[16px] py-[8px] text-[#62646A99] font-poppins lg:w-[40%]">
              {" "}
              <div className="flex items-center gap-[8px]">
                Sent To
                <img src={Sorting} alt="" />
              </div>
            </td>
            <td className="py-[8px] text-[#62646A99] font-poppins lg:w-[25%]">
              {" "}
              <div className="flex items-center justify-center gap-[8px]">
                Date
                <img src={Sorting} alt="" />
              </div>
            </td>
            <td className="py-[8px] text-[#62646A99] font-poppins lg:w-[25%]">
              {" "}
              <div className="flex items-center gap-[8px]">
                Message
                <img src={Sorting} alt="" />
              </div>
            </td>
            <td className="py-[8px] text-[#62646A99] font-poppins lg:w-[10%]"></td>
          </tr>
        </thead>
        <tbody>
          {data?.map((da) => (
            <tr key={da.message}>
              <td className="pl-[16px] py-[16px] text-[#333333] font-medium font-poppins">
                {da.emails[0]} (
                {Math.max(da.emails.length - 1, 0)} others)
              </td>
              <td className="py-[16px] text-[#333333] font-medium font-poppins text-center">
                {da.date}
              </td>
              <td className="py-[16px] text-[#333333] font-medium font-poppins">
                {da.message}
              </td>
              <td className="text-[#333333] font-medium font-poppins">
                <div
                  onClick={() => {
                    setSelected(da);
                    setIsOpen(true);
                  }}
                  className="py-[16px] flex items-center justify-end pr-4 cursor-pointer"
                >
                  <img src={More} alt="" />
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <div className="lg:hidden flex flex-col gap-[16px] w-[100%]">
        {data.map((da) => (
          <div
            key={da.message}
            className="flex flex-col border border-[#C8C8C8] rounded-lg p-4 space-y-6 font-poppins"
          >
            <div
              onClick={() => {
                setSelected(da);
                setIsOpen(true);
              }}
              className="flex justify-end cursor-pointer"
            >
              <img src={More} alt="" />
            </div>
            <div className="flex items-center justify-between flex-wrap gap-6">
              <div>
                <p className="text-[0.75rem] text-[#62646A99] mb-1">SENT TO</p>
                <p className="text-[0.875rem] text-[#333333] font-medium">
                  {da.emails[0]} (
                  {Math.max(da.emails.length - 1, 0)} others)
                </p>
              </div>
              <div>
                <p className="text-[0.75rem] text-[#62646A99] mb-1">DATE</p>
                <p className="text-[0.875rem] text-[#333333] font-medium">
                  {da.date}
                </p>
              </div>
            </div>
            <div className="flex items-center justify-between flex-wrap gap-6">
              <div>
                <p className="text-[0.75rem] text-[#62646A99] mb-1">MESSAGE</p>
                <p className="text-[0.875rem] text-[#333333] font-medium">
                  {" "}
                  {da.message}
                </p>
              </div>
            </div>
          </div>
        ))}
      </div>

      <Dialog
        open={isOpen}
        onClose={() => {
          setIsOpen(false);
        }}
        className="relative z-50"
      >
        <div className="fixed inset-0 flex w-screen items-center bg-black/70 justify-center p-4">
          <Dialog.Panel className="lg:space-y-6 border bg-white lg:p-5 p-2 rounded-[8px] lg:w-[620px] w-[100%] font-poppins">
            <div className="flex items-center justify-between">
              <CSVLink data={[CSVdata]} filename={"users_data.csv"}>
                <button className="text-[#3EB211] font-poppins text-[0.875rem] font-medium">
                  Export CSV
                </button>
              </CSVLink>
              <img
                className="cursor-pointer"
                onClick={() => {
                  setIsOpen(false);
                }}
                src={Close}
                alt=""
              />
            </div>
            <div className="flex flex-col items-center justify-center gap-[8px] border-b border-b-[#E4E4E4] lg:pb-[24px] pb-[12px]">
              <h1 className="text-center font-medium text-[1.125rem]">
                Notification Details
              </h1>
            </div>
            <div className="border border-[#E4E4E4] rounded-[8px] lg:p-[24px] p-[6px] mt-[24px]">
              <div className="flex items-center justify-between mb-[24px]">
                <div className="w-[100%]">
                  <h1 className="text-[#62646A99] text-[0.75rem] font-medium">
                    EMAILS
                  </h1>
                  <div className="text-[0.875rem] text-[#333333] font-medium flex items-center gap-5 flex-wrap">
                    {selected?.emails.map((em) => (
                      <p key={em} className="p-1 rounded-[6px] bg-[#F1F1F1] w-max">{em}</p>
                    ))}
                  </div>
                </div>
              </div>
            </div>
            <div className="border border-[#E4E4E4] rounded-[8px] lg:p-[24px] p-[6px] mt-[24px]">
              <div className="flex items-center justify-between mb-[24px]">
                <div className="flex items-center justify-between mb-[24px] flex-wrap gap-6 w-[100%]">
                  <div>
                    <h1 className="text-[#62646A99] text-[0.75rem] font-medium">
                      MESSAGE
                    </h1>
                    <p className="text-[0.875rem] text-[#333333] font-medium lg:w-[300px]">
                      {selected?.message}
                    </p>
                  </div>
                  <div>
                    <h1 className="text-[#62646A99] text-[0.75rem] font-medium lg:text-right">
                      DATE
                    </h1>
                    <p className="text-[0.875rem] text-[#333333] font-medium lg:text-right">
                      {selected?.date}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </Dialog.Panel>
        </div>
      </Dialog>
    </div>
  );
}

export default NotifTable;
