import React, { Fragment, useEffect, useState } from "react";
import Sidebar from "../../components/new-design/Sidebar";
import Topbar from "../../components/new-design/Topbar";
import UsersBlack from "../../assets/new-design/heroicons_user-20-solid-black.svg";
import QuizzesBlack from "../../assets/new-design/material-symbols_quiz-black.svg";
import LearingBlack from "../../assets/new-design/ic_round-school-black.svg";
import BenefitsBlack from "../../assets/new-design/ion_gift-black.svg";
import AdvocacyBlack from "../../assets/new-design/mage_megaphone-a-fill-black.svg";
import ActivitiesBlack from "../../assets/new-design/icon-park-solid_activity-source-black.svg";
import MainLineChart from "../../components/new-design/Charts/MainLineChart";
import Chevron from "../../assets/new-design/ep_arrow-up-bold.svg";
import { Dialog, Menu, Transition } from "@headlessui/react";
import axios from "axios";
import { Triangle } from "react-loader-spinner";

function convertDate(isoDate) {
  //   const isoDate = "2023-06-23T20:37:03.658Z";

  // Create a new Date object from the ISO string
  const date = new Date(isoDate);

  // Extract day, month, and year
  const day = String(date.getDate()).padStart(2, "0");
  const month = date.toLocaleString("default", { month: "long" });
  const year = date.getFullYear();

  // Format the date as "dd mm, yyyy"
  const formattedDate = `${day} ${month}, ${year}`;

  return formattedDate; // Output: 23 June, 2023
}

function Analytics() {
  const [selectedTime, setSelectedTime] = useState("1w");
  const [graphSelectedTime, setGraphSelectedTime] = useState("1w");
  const [loading, setLoading] = useState(false);
  const [graphLoading, setGraphLoading] = useState(false);
  const [stats, setStats] = useState();
  const [graphData, setGraphData] = useState({});
  const [selectedGraphData, setSelectedGraphData] = useState([]);
  const [filter1, setFilter1] = useState("user");
  const [filter2, setFilter2] = useState("quizResult");
  const [selected, setSelected] = useState(null);
  const [selectedName, setSelectedName] = useState("");

  console.log(selected);
  console.log(selectedName);

  // console.log(selectedGraphData);

  // console.log(stats)

  useEffect(() => {
    const getLearnings = async () => {
      setLoading(true);
      try {
        const res = await axios.get(
          "https://hero-backend-july10-72654a654b93.herokuapp.com/api/dashboard/stats"
        );
        console.log(res.data);
        setLoading(false);
        setStats(res.data.data);
      } catch (error) {
        console.log(error);
      }
    };
    const getGraph = async () => {
      setGraphLoading(true);
      try {
        const res = await axios.get(
          "https://hero-backend-july10-72654a654b93.herokuapp.com/api/dashboard/graph"
        );
        console.log(res.data);
        setGraphLoading(false);
        setGraphData(res.data);
      } catch (error) {
        console.log(error);
      }
    };
    getGraph();
    getLearnings();
  }, []);

  useEffect(() => {
    if (graphSelectedTime === "1w") {
      setSelectedGraphData(graphData.last_7_days);
    }
    if (graphSelectedTime === "1m") {
      setSelectedGraphData(graphData.last_30_days);
    }
    if (graphSelectedTime === "6m") {
      setSelectedGraphData(graphData.last_6_months);
    }
    if (graphSelectedTime === "1yr") {
      setSelectedGraphData(graphData.last_1_year);
    }
    if (graphSelectedTime === "all") {
      setSelectedGraphData(graphData.all);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [graphSelectedTime, graphData]);

  return (
    <div className="flex h-[100vh]">
      <Sidebar />
      <div className="lg:w-[82%] w-[100%]">
        <Topbar />

        <div className="lg:p-[32px] p-[16px] h-[88vh] overflow-y-scroll">
          <div className="flex items-center justify-between mb-4 font-poppins">
            <p className="text-[#1E1E1E] font-medium text-[0.875rem]">
              Overview
            </p>
            {/* <p className="text-[#3EB211] font-medium text-[0.875rem]">
              Export CSV
            </p> */}
          </div>
          <div>
            <div className="mb-[13px]">
              <div className="flex flex-col lg:flex-row items-center gap-[20px] mb-4">
                <div className="relative">
                  <Menu>
                    <Menu.Button className="flex items-center justify-between gap-[10px] bg-[#6EDA441A] border-[#6EDA44] w-[13rem] border rounded-[8px] p-2 font-poppins font-medium text-[#333333] lg:text-[0.875rem] text-[0.75rem]">
                      {filter1 === "user" && "Total No. of Users"}
                      {filter1 === "quizResult" && "Quiz Results"}
                      {filter1 === "completedLearning" && "Learnings Completed"}
                      {filter1 === "earnings" && "Rewards Earned"}
                      {filter1 === "advocacy" && "Advocacies Sent"}
                      {filter1 === "activity" && "Activities Completed"}
                      <img src={Chevron} alt="" />
                    </Menu.Button>
                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Menu.Items
                        as="div"
                        className="absolute w-[11.2rem] z-10 lg:mt-[1rem] mt-[0.1rem] lg:mr-[5rem] origin-top-right divide-y divide-gray-300 rounded-[0.75rem] bg-[#6EDA441A] border border-[#6EDA44] ring-1 ring-black ring-opacity-5 focus:outline-none flex flex-col"
                      >
                        {filter2 !== "user" && (
                          <Menu.Item
                            onClick={() => setFilter1("user")}
                            as="div"
                            className="hover:bg-[#6EDA44] rounded-t-[0.75rem] cursor-pointer hover:text-white p-2 font-poppins font-medium text-[#333333] lg:text-[0.875rem] text-[0.75rem]"
                          >
                            TOTAL NO. OF USERS
                          </Menu.Item>
                        )}
                        {filter2 !== "quizResult" && (
                          <Menu.Item
                            onClick={() => setFilter1("quizResult")}
                            as="div"
                            className={`${
                              filter2 === "user" && "rounded-t-[0.75rem]"
                            } hover:bg-[#6EDA44] cursor-pointer hover:text-white p-2 font-poppins font-medium text-[#333333] lg:text-[0.875rem] text-[0.75rem]`}
                          >
                            QUIZ RESULTS
                          </Menu.Item>
                        )}
                        {filter2 !== "completedLearning" && (
                          <Menu.Item
                            onClick={() => setFilter1("completedLearning")}
                            as="div"
                            className="hover:bg-[#6EDA44] cursor-pointer hover:text-white p-2 font-poppins font-medium text-[#333333] lg:text-[0.875rem] text-[0.75rem]"
                          >
                            LEARNINGS COMPLETED
                          </Menu.Item>
                        )}
                        {filter2 !== "earnings" && (
                          <Menu.Item
                            onClick={() => setFilter1("earnings")}
                            as="div"
                            className="hover:bg-[#6EDA44] cursor-pointer hover:text-white p-2 font-poppins font-medium text-[#333333] lg:text-[0.875rem] text-[0.75rem]"
                          >
                            REWARDS EARNED
                          </Menu.Item>
                        )}
                        {filter2 !== "advocacy" && (
                          <Menu.Item
                            onClick={() => setFilter1("advocacy")}
                            as="div"
                            className={`${
                              filter2 === "activity" && "rounded-b-[0.75rem]"
                            } hover:bg-[#6EDA44] cursor-pointer hover:text-white p-2 font-poppins font-medium text-[#333333] lg:text-[0.875rem] text-[0.75rem]`}
                          >
                            ADVOCACIES SENT
                          </Menu.Item>
                        )}
                        {filter2 !== "activity" && (
                          <Menu.Item
                            onClick={() => setFilter1("activity")}
                            as="div"
                            className="hover:bg-[#6EDA44] rounded-b-[0.75rem] cursor-pointer hover:text-white p-2 font-poppins font-medium text-[#333333] lg:text-[0.875rem] text-[0.75rem]"
                          >
                            ACTIVITIES COMPLETED
                          </Menu.Item>
                        )}
                      </Menu.Items>
                    </Transition>
                  </Menu>
                </div>
                <div className="relative">
                  <Menu>
                    <Menu.Button className="flex items-center gap-[10px] justify-between w-[13rem] bg-[#4E1EE31A] border-[#4E1EE3] border rounded-[8px] p-2 font-poppins font-medium text-[#333333] lg:text-[0.875rem] text-[0.75rem]">
                      {filter2 === "user" && "Total No. of Users"}
                      {filter2 === "quizResult" && "Quiz Results"}
                      {filter2 === "completedLearning" && "Learnings Completed"}
                      {filter2 === "earnings" && "Rewards Earned"}
                      {filter2 === "advocacy" && "Advocacies Sent"}
                      {filter2 === "activity" && "Activities Completed"}
                      <img src={Chevron} alt="" />
                    </Menu.Button>
                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Menu.Items
                        as="div"
                        className="absolute bg-[#4E1EE31A] border border-[#4E1EE3] w-[11.2rem] z-10 lg:mt-[1rem] mt-[0.1rem] lg:mr-[5rem] origin-top-right divide-y divide-gray-300 rounded-[0.75rem] ring-1 ring-black ring-opacity-5 focus:outline-none flex flex-col"
                      >
                        {filter1 !== "user" && (
                          <Menu.Item
                            onClick={() => setFilter2("user")}
                            as="div"
                            className="hover:bg-[#4E1EE3] cursor-pointer rounded-t-[0.75rem] hover:text-white p-2 font-poppins font-medium text-[#333333] lg:text-[0.875rem] text-[0.75rem]"
                          >
                            TOTAL NO. OF USERS
                          </Menu.Item>
                        )}
                        {filter1 !== "quizResult" && (
                          <Menu.Item
                            onClick={() => setFilter2("quizResult")}
                            as="div"
                            className={`${
                              filter1 === "user" && "rounded-t-[0.75rem]"
                            } hover:bg-[#4E1EE3] cursor-pointer hover:text-white p-2 font-poppins font-medium text-[#333333] lg:text-[0.875rem] text-[0.75rem]`}
                          >
                            QUIZ RESULTS
                          </Menu.Item>
                        )}
                        {filter1 !== "completedLearning" && (
                          <Menu.Item
                            onClick={() => setFilter2("completedLearning")}
                            as="div"
                            className="hover:bg-[#4E1EE3] cursor-pointer hover:text-white p-2 font-poppins font-medium text-[#333333] lg:text-[0.875rem] text-[0.75rem]"
                          >
                            LEARNINGS COMPLETED
                          </Menu.Item>
                        )}
                        {filter1 !== "earnings" && (
                          <Menu.Item
                            onClick={() => setFilter2("earnings")}
                            as="div"
                            className="hover:bg-[#4E1EE3] cursor-pointer hover:text-white p-2 font-poppins font-medium text-[#333333] lg:text-[0.875rem] text-[0.75rem]"
                          >
                            REWARDS EARNED
                          </Menu.Item>
                        )}
                        {filter1 !== "advocacy" && (
                          <Menu.Item
                            onClick={() => setFilter2("advocacy")}
                            as="div"
                            className={`${
                              filter1 === "activity" && "rounded-b-[0.75rem]"
                            } hover:bg-[#4E1EE3] cursor-pointer hover:text-white p-2 font-poppins font-medium text-[#333333] lg:text-[0.875rem] text-[0.75rem]`}
                          >
                            ADVOCACIES SENT
                          </Menu.Item>
                        )}
                        {filter1 !== "activity" && (
                          <Menu.Item
                            onClick={() => setFilter2("activity")}
                            as="div"
                            className="hover:bg-[#4E1EE3] cursor-pointer rounded-b-[0.75rem] hover:text-white p-2 font-poppins font-medium text-[#333333] lg:text-[0.875rem] text-[0.75rem]"
                          >
                            ACTIVITIES COMPLETED
                          </Menu.Item>
                        )}
                      </Menu.Items>
                    </Transition>
                  </Menu>
                </div>
              </div>
              <div className="flex justify-end mb-4">
                <div className="flex items-center border border-[#E4E4E4] rounded-[8px] font-poppins text-[0.75rem] p-[2px]">
                  <p
                    onClick={() => setGraphSelectedTime("1w")}
                    className={`py-2 cursor-pointer px-4 ${
                      graphSelectedTime === "1w" &&
                      "bg-[#F1F1F1] border border-[#E4E4E4] rounded-[8px]"
                    }`}
                  >
                    1 W
                  </p>
                  <p
                    onClick={() => setGraphSelectedTime("1m")}
                    className={`py-2 cursor-pointer px-4 ${
                      graphSelectedTime === "1m" &&
                      "bg-[#F1F1F1] border border-[#E4E4E4] rounded-[8px]"
                    }`}
                  >
                    1 M
                  </p>
                  <p
                    onClick={() => setGraphSelectedTime("6m")}
                    className={`py-2 cursor-pointer px-4 ${
                      graphSelectedTime === "6m" &&
                      "bg-[#F1F1F1] border border-[#E4E4E4] rounded-[8px]"
                    }`}
                  >
                    6 M
                  </p>
                  <p
                    onClick={() => setGraphSelectedTime("1yr")}
                    className={`py-2 cursor-pointer px-4 ${
                      graphSelectedTime === "1yr" &&
                      "bg-[#F1F1F1] border border-[#E4E4E4] rounded-[8px]"
                    }`}
                  >
                    1 YR
                  </p>
                  <p
                    onClick={() => setGraphSelectedTime("all")}
                    className={`py-2 cursor-pointer px-4 ${
                      graphSelectedTime === "all" &&
                      "bg-[#F1F1F1] border border-[#E4E4E4] rounded-[8px]"
                    }`}
                  >
                    ALL
                  </p>
                </div>
              </div>
            </div>
            {graphLoading ? (
              <div className="w-[100%] h-[50vh] flex items-center justify-center">
                <Triangle
                  visible={true}
                  height="80"
                  width="80"
                  color="#3EB211"
                  ariaLabel="triangle-loading"
                  wrapperStyle={{}}
                  wrapperClass=""
                />
              </div>
            ) : (
              <div className="w-[90%]">
                <MainLineChart
                  data={selectedGraphData?.map((dat) => ({
                    name: dat["date"],
                    uv: dat[filter1],
                    pv: dat[filter2],
                  }))}
                  name={
                    graphSelectedTime === "1w"
                      ? "1 Week"
                      : graphSelectedTime === "1m"
                      ? "1 Month"
                      : graphSelectedTime === "6m"
                      ? "6 Months"
                      : graphSelectedTime === "1yr"
                      ? "1 Year"
                      : "Total"
                  }
                />
              </div>
            )}
          </div>

          {loading || !stats ? (
            <div className="w-[100%] h-[50vh] flex items-center justify-center">
              <Triangle
                visible={true}
                height="80"
                width="80"
                color="#3EB211"
                ariaLabel="triangle-loading"
                wrapperStyle={{}}
                wrapperClass=""
              />
            </div>
          ) : (
            <div className="mt-10">
              <div className="flex justify-end mb-4">
                <div className="flex items-center border border-[#E4E4E4] rounded-[8px] font-poppins text-[0.75rem] p-[2px]">
                  <p
                    onClick={() => setSelectedTime("1w")}
                    className={`py-2 cursor-pointer px-4 ${
                      selectedTime === "1w" &&
                      "bg-[#F1F1F1] border border-[#E4E4E4] rounded-[8px]"
                    }`}
                  >
                    1 W
                  </p>
                  <p
                    onClick={() => setSelectedTime("1m")}
                    className={`py-2 cursor-pointer px-4 ${
                      selectedTime === "1m" &&
                      "bg-[#F1F1F1] border border-[#E4E4E4] rounded-[8px]"
                    }`}
                  >
                    1 M
                  </p>
                  <p
                    onClick={() => setSelectedTime("6m")}
                    className={`py-2 cursor-pointer px-4 ${
                      selectedTime === "6m" &&
                      "bg-[#F1F1F1] border border-[#E4E4E4] rounded-[8px]"
                    }`}
                  >
                    6 M
                  </p>
                  <p
                    onClick={() => setSelectedTime("1yr")}
                    className={`py-2 cursor-pointer px-4 ${
                      selectedTime === "1yr" &&
                      "bg-[#F1F1F1] border border-[#E4E4E4] rounded-[8px]"
                    }`}
                  >
                    1 YR
                  </p>
                  <p
                    onClick={() => setSelectedTime("all")}
                    className={`py-2 cursor-pointer px-4 ${
                      selectedTime === "all" &&
                      "bg-[#F1F1F1] border border-[#E4E4E4] rounded-[8px]"
                    }`}
                  >
                    ALL
                  </p>
                </div>
              </div>
              <div className="grid lg:grid-cols-2 gap-4 font-poppins">
                <div className="border border-[#E4E4E4] rounded-[8px] py-10 pl-6 flex items-center gap-6">
                  <img
                    src={UsersBlack}
                    alt=""
                    className="p-4 bg-[#62646A4D] rounded-full"
                    onClick={() => {
                      setSelectedName("user");
                      selectedTime === "1w" &&
                        setSelected(stats?.oneWeekDoc[0]);
                      selectedTime === "1m" &&
                        setSelected(stats?.oneMonthDoc[0]);
                      selectedTime === "6m" &&
                        setSelected(stats?.sixMonthsDoc[0]);
                      selectedTime === "1yr" &&
                        setSelected(stats?.oneYearDoc[0]);
                    }}
                  />
                  <div className="flex flex-col gap-2">
                    <p className="font-medium text-[0.75rem] text-[#333333]">
                      TOTAL NO. OF USERS
                    </p>
                    <p className="font-semibold text-3xl text-[#333333]">
                      {selectedTime === "1w" && stats?.oneWeek[0]}
                      {selectedTime === "1m" && stats?.oneMonth[0]}
                      {selectedTime === "6m" && stats?.sixMonths[0]}
                      {selectedTime === "1yr" && stats?.oneYear[0]}
                      {selectedTime === "all" && stats?.all[0]}
                    </p>
                    <p className="text-[#1E1E1EB2] font-medium text-[0.75rem]">
                      {selectedTime === "1w" && (
                        <span
                          className={`${
                            stats?.weekPercent[0] < 1
                              ? "text-[#B41007] bg-[#B410071A]"
                              : "text-[#3EB211] bg-[#6EDA441A]"
                          } font-medium text-[0.875rem] rounded-[100px] py-[2px] px-2`}
                        >
                          {stats?.weekPercent[0]}%
                        </span>
                      )}
                      {selectedTime === "1m" && (
                        <span
                          className={`${
                            stats?.monthPercent[0] < 1
                              ? "text-[#B41007] bg-[#B410071A]"
                              : "text-[#3EB211] bg-[#6EDA441A]"
                          } font-medium text-[0.875rem] rounded-[100px] py-[2px] px-2`}
                        >
                          {stats?.monthPercent[0]}%
                        </span>
                      )}
                      {selectedTime === "6m" && (
                        <span
                          className={`${
                            stats?.sixMonthsPercent[0] < 1
                              ? "text-[#B41007] bg-[#B410071A]"
                              : "text-[#3EB211] bg-[#6EDA441A]"
                          } font-medium text-[0.875rem] rounded-[100px] py-[2px] px-2`}
                        >
                          {stats?.sixMonthsPercent[0]}%
                        </span>
                      )}
                      {selectedTime === "1yr" && (
                        <span
                          className={`${
                            stats?.oneYearPercent[0] < 1
                              ? "text-[#B41007] bg-[#B410071A]"
                              : "text-[#3EB211] bg-[#6EDA441A]"
                          } font-medium text-[0.875rem] rounded-[100px] py-[2px] px-2`}
                        >
                          {stats?.oneYearPercent[0]}%
                        </span>
                      )}
                      {selectedTime === "all" && (
                        <span
                          className={`text-[#3EB211] bg-[#6EDA441A] font-medium text-[0.875rem] rounded-[100px] py-[2px] px-2`}
                        >
                          100%
                        </span>
                      )}{" "}
                      {selectedTime === "1w" && "Last One Week"}
                      {selectedTime === "1m" && "Last One Month"}
                      {selectedTime === "6m" && "Last Six Months"}
                      {selectedTime === "1yr" && "Last One Year"}
                      {selectedTime === "all" && "Total"}
                    </p>
                  </div>
                </div>
                <div className="border border-[#E4E4E4] rounded-[8px] py-10 pl-6 flex items-center gap-6">
                  <img
                    src={QuizzesBlack}
                    alt=""
                    className="p-4 bg-[#62646A4D] rounded-full"
                    onClick={() => {
                      setSelectedName("quiz");
                      selectedTime === "1w" &&
                        setSelected(stats?.oneWeekDoc[1]);
                      selectedTime === "1m" &&
                        setSelected(stats?.oneMonthDoc[1]);
                      selectedTime === "6m" &&
                        setSelected(stats?.sixMonthsDoc[1]);
                      selectedTime === "1yr" &&
                        setSelected(stats?.oneYearDoc[1]);
                    }}
                  />
                  <div className="flex flex-col gap-2">
                    <p className="font-medium text-[0.75rem] text-[#333333]">
                      QUIZ RESULTS
                    </p>
                    <p className="font-semibold text-3xl text-[#333333]">
                      {selectedTime === "1w" && stats?.oneWeek[1]}
                      {selectedTime === "1m" && stats?.oneMonth[1]}
                      {selectedTime === "6m" && stats?.sixMonths[1]}
                      {selectedTime === "1yr" && stats?.oneYear[1]}
                      {selectedTime === "all" && stats?.all[1]}
                    </p>
                    <p className="text-[#1E1E1EB2] font-medium text-[0.75rem]">
                      {selectedTime === "1w" && (
                        <span
                          className={`${
                            stats?.weekPercent[1] < 1
                              ? "text-[#B41007] bg-[#B410071A]"
                              : "text-[#3EB211] bg-[#6EDA441A]"
                          } font-medium text-[0.875rem] rounded-[100px] py-[2px] px-2`}
                        >
                          {stats?.weekPercent[1]}%
                        </span>
                      )}
                      {selectedTime === "1m" && (
                        <span
                          className={`${
                            stats?.monthPercent[1] < 1
                              ? "text-[#B41007] bg-[#B410071A]"
                              : "text-[#3EB211] bg-[#6EDA441A]"
                          } font-medium text-[0.875rem] rounded-[100px] py-[2px] px-2`}
                        >
                          {stats?.monthPercent[1]}%
                        </span>
                      )}
                      {selectedTime === "6m" && (
                        <span
                          className={`${
                            stats?.sixMonthsPercent[1] < 1
                              ? "text-[#B41007] bg-[#B410071A]"
                              : "text-[#3EB211] bg-[#6EDA441A]"
                          } font-medium text-[0.875rem] rounded-[100px] py-[2px] px-2`}
                        >
                          {stats?.sixMonthsPercent[1]}%
                        </span>
                      )}
                      {selectedTime === "1yr" && (
                        <span
                          className={`${
                            stats?.oneYearPercent[1] < 1
                              ? "text-[#B41007] bg-[#B410071A]"
                              : "text-[#3EB211] bg-[#6EDA441A]"
                          } font-medium text-[0.875rem] rounded-[100px] py-[2px] px-2`}
                        >
                          {stats?.oneYearPercent[1]}%
                        </span>
                      )}
                      {selectedTime === "all" && (
                        <span
                          className={`text-[#3EB211] bg-[#6EDA441A] font-medium text-[0.875rem] rounded-[100px] py-[2px] px-2`}
                        >
                          100%
                        </span>
                      )}{" "}
                      {selectedTime === "1w" && "Last One Week"}
                      {selectedTime === "1m" && "Last One Month"}
                      {selectedTime === "6m" && "Last Six Months"}
                      {selectedTime === "1yr" && "Last One Year"}
                      {selectedTime === "all" && "Total"}
                    </p>
                  </div>
                </div>
                <div className="border border-[#E4E4E4] rounded-[8px] py-10 pl-6 flex items-center gap-6">
                  <img
                    src={LearingBlack}
                    alt=""
                    className="p-4 bg-[#62646A4D] rounded-full"
                    onClick={() => {
                      setSelectedName("learn");
                      selectedTime === "1w" &&
                        setSelected(stats?.oneWeekDoc[2]);
                      selectedTime === "1m" &&
                        setSelected(stats?.oneMonthDoc[2]);
                      selectedTime === "6m" &&
                        setSelected(stats?.sixMonthsDoc[2]);
                      selectedTime === "1yr" &&
                        setSelected(stats?.oneYearDoc[2]);
                    }}
                  />
                  <div className="flex flex-col gap-2">
                    <p className="font-medium text-[0.75rem] text-[#333333]">
                      LEARNINGS COMPLETED
                    </p>
                    <p className="font-semibold text-3xl text-[#333333]">
                      {selectedTime === "1w" && stats?.oneWeek[2]}
                      {selectedTime === "1m" && stats?.oneMonth[2]}
                      {selectedTime === "6m" && stats?.sixMonths[2]}
                      {selectedTime === "1yr" && stats?.oneYear[2]}
                      {selectedTime === "all" && stats?.all[2]}
                    </p>
                    <p className="text-[#1E1E1EB2] font-medium text-[0.75rem]">
                      {selectedTime === "1w" && (
                        <span
                          className={`${
                            stats?.weekPercent[2] < 1
                              ? "text-[#B41007] bg-[#B410071A]"
                              : "text-[#3EB211] bg-[#6EDA441A]"
                          } font-medium text-[0.875rem] rounded-[100px] py-[2px] px-2`}
                        >
                          {stats?.weekPercent[2]}%
                        </span>
                      )}
                      {selectedTime === "1m" && (
                        <span
                          className={`${
                            stats?.monthPercent[2] < 1
                              ? "text-[#B41007] bg-[#B410071A]"
                              : "text-[#3EB211] bg-[#6EDA441A]"
                          } font-medium text-[0.875rem] rounded-[100px] py-[2px] px-2`}
                        >
                          {stats?.monthPercent[2]}%
                        </span>
                      )}
                      {selectedTime === "6m" && (
                        <span
                          className={`${
                            stats?.sixMonthsPercent[2] < 1
                              ? "text-[#B41007] bg-[#B410071A]"
                              : "text-[#3EB211] bg-[#6EDA441A]"
                          } font-medium text-[0.875rem] rounded-[100px] py-[2px] px-2`}
                        >
                          {stats?.sixMonthsPercent[2]}%
                        </span>
                      )}
                      {selectedTime === "1yr" && (
                        <span
                          className={`${
                            stats?.oneYearPercent[2] < 1
                              ? "text-[#B41007] bg-[#B410071A]"
                              : "text-[#3EB211] bg-[#6EDA441A]"
                          } font-medium text-[0.875rem] rounded-[100px] py-[2px] px-2`}
                        >
                          {stats?.oneYearPercent[2]}%
                        </span>
                      )}
                      {selectedTime === "all" && (
                        <span
                          className={`text-[#3EB211] bg-[#6EDA441A] font-medium text-[0.875rem] rounded-[100px] py-[2px] px-2`}
                        >
                          100%
                        </span>
                      )}{" "}
                      {selectedTime === "1w" && "Last One Week"}
                      {selectedTime === "1m" && "Last One Month"}
                      {selectedTime === "6m" && "Last Six Months"}
                      {selectedTime === "1yr" && "Last One Year"}
                      {selectedTime === "all" && "Total"}
                    </p>
                  </div>
                </div>
                <div className="border border-[#E4E4E4] rounded-[8px] py-10 pl-6 flex items-center gap-6">
                  <img
                    src={BenefitsBlack}
                    alt=""
                    className="p-4 bg-[#62646A4D] rounded-full"
                    onClick={() => {
                      setSelectedName("reward");
                      selectedTime === "1w" &&
                        setSelected(stats?.oneWeekDoc[3]);
                      selectedTime === "1m" &&
                        setSelected(stats?.oneMonthDoc[3]);
                      selectedTime === "6m" &&
                        setSelected(stats?.sixMonthsDoc[3]);
                      selectedTime === "1yr" &&
                        setSelected(stats?.oneYearDoc[3]);
                    }}
                  />
                  <div className="flex flex-col gap-2">
                    <p className="font-medium text-[0.75rem] text-[#333333]">
                      REWARDS EARNED
                    </p>
                    <p className="font-semibold text-3xl text-[#333333]">
                      {selectedTime === "1w" && stats?.oneWeek[3]}
                      {selectedTime === "1m" && stats?.oneMonth[3]}
                      {selectedTime === "6m" && stats?.sixMonths[3]}
                      {selectedTime === "1yr" && stats?.oneYear[3]}
                      {selectedTime === "all" && stats?.all[3]}
                    </p>
                    <p className="text-[#1E1E1EB2] font-medium text-[0.75rem]">
                      {selectedTime === "1w" && (
                        <span
                          className={`${
                            stats?.weekPercent[3] < 1
                              ? "text-[#B41007] bg-[#B410071A]"
                              : "text-[#3EB211] bg-[#6EDA441A]"
                          } font-medium text-[0.875rem] rounded-[100px] py-[2px] px-2`}
                        >
                          {stats?.weekPercent[3]}%
                        </span>
                      )}
                      {selectedTime === "1m" && (
                        <span
                          className={`${
                            stats?.monthPercent[3] < 1
                              ? "text-[#B41007] bg-[#B410071A]"
                              : "text-[#3EB211] bg-[#6EDA441A]"
                          } font-medium text-[0.875rem] rounded-[100px] py-[2px] px-2`}
                        >
                          {stats?.monthPercent[3]}%
                        </span>
                      )}
                      {selectedTime === "6m" && (
                        <span
                          className={`${
                            stats?.sixMonthsPercent[3] < 1
                              ? "text-[#B41007] bg-[#B410071A]"
                              : "text-[#3EB211] bg-[#6EDA441A]"
                          } font-medium text-[0.875rem] rounded-[100px] py-[2px] px-2`}
                        >
                          {stats?.sixMonthsPercent[3]}%
                        </span>
                      )}
                      {selectedTime === "1yr" && (
                        <span
                          className={`${
                            stats?.oneYearPercent[3] < 1
                              ? "text-[#B41007] bg-[#B410071A]"
                              : "text-[#3EB211] bg-[#6EDA441A]"
                          } font-medium text-[0.875rem] rounded-[100px] py-[2px] px-2`}
                        >
                          {stats?.oneYearPercent[3]}%
                        </span>
                      )}
                      {selectedTime === "all" && (
                        <span
                          className={`text-[#3EB211] bg-[#6EDA441A] font-medium text-[0.875rem] rounded-[100px] py-[2px] px-2`}
                        >
                          100%
                        </span>
                      )}{" "}
                      {selectedTime === "1w" && "Last One Week"}
                      {selectedTime === "1m" && "Last One Month"}
                      {selectedTime === "6m" && "Last Six Months"}
                      {selectedTime === "1yr" && "Last One Year"}
                      {selectedTime === "all" && "Total"}
                    </p>
                  </div>
                </div>
                <div className="border border-[#E4E4E4] rounded-[8px] py-10 pl-6 flex items-center gap-6">
                  <img
                    src={AdvocacyBlack}
                    alt=""
                    className="p-4 bg-[#62646A4D] rounded-full"
                    onClick={() => {
                      setSelectedName("advocate");
                      selectedTime === "1w" &&
                        setSelected(stats?.oneWeekDoc[4]);
                      selectedTime === "1m" &&
                        setSelected(stats?.oneMonthDoc[4]);
                      selectedTime === "6m" &&
                        setSelected(stats?.sixMonthsDoc[4]);
                      selectedTime === "1yr" &&
                        setSelected(stats?.oneYearDoc[4]);
                    }}
                  />
                  <div className="flex flex-col gap-2">
                    <p className="font-medium text-[0.75rem] text-[#333333]">
                      ADVOCACIES SENT
                    </p>
                    <p className="font-semibold text-3xl text-[#333333]">
                      {selectedTime === "1w" && stats?.oneWeek[4]}
                      {selectedTime === "1m" && stats?.oneMonth[4]}
                      {selectedTime === "6m" && stats?.sixMonths[4]}
                      {selectedTime === "1yr" && stats?.oneYear[4]}
                      {selectedTime === "all" && stats?.all[4]}
                    </p>
                    <p className="text-[#1E1E1EB2] font-medium text-[0.75rem]">
                      {selectedTime === "1w" && (
                        <span
                          className={`${
                            stats?.weekPercent[4] < 1
                              ? "text-[#B41007] bg-[#B410071A]"
                              : "text-[#3EB211] bg-[#6EDA441A]"
                          } font-medium text-[0.875rem] rounded-[100px] py-[2px] px-2`}
                        >
                          {stats?.weekPercent[4]}%
                        </span>
                      )}
                      {selectedTime === "1m" && (
                        <span
                          className={`${
                            stats?.monthPercent[4] < 1
                              ? "text-[#B41007] bg-[#B410071A]"
                              : "text-[#3EB211] bg-[#6EDA441A]"
                          } font-medium text-[0.875rem] rounded-[100px] py-[2px] px-2`}
                        >
                          {stats?.monthPercent[4]}%
                        </span>
                      )}
                      {selectedTime === "6m" && (
                        <span
                          className={`${
                            stats?.sixMonthsPercent[4] < 1
                              ? "text-[#B41007] bg-[#B410071A]"
                              : "text-[#3EB211] bg-[#6EDA441A]"
                          } font-medium text-[0.875rem] rounded-[100px] py-[2px] px-2`}
                        >
                          {stats?.sixMonthsPercent[4]}%
                        </span>
                      )}
                      {selectedTime === "1yr" && (
                        <span
                          className={`${
                            stats?.oneYearPercent[4] < 1
                              ? "text-[#B41007] bg-[#B410071A]"
                              : "text-[#3EB211] bg-[#6EDA441A]"
                          } font-medium text-[0.875rem] rounded-[100px] py-[2px] px-2`}
                        >
                          {stats?.oneYearPercent[4]}%
                        </span>
                      )}
                      {selectedTime === "all" && (
                        <span
                          className={`text-[#3EB211] bg-[#6EDA441A] font-medium text-[0.875rem] rounded-[100px] py-[2px] px-2`}
                        >
                          100%
                        </span>
                      )}{" "}
                      {selectedTime === "1w" && "Last One Week"}
                      {selectedTime === "1m" && "Last One Month"}
                      {selectedTime === "6m" && "Last Six Months"}
                      {selectedTime === "1yr" && "Last One Year"}
                      {selectedTime === "all" && "Total"}
                    </p>
                  </div>
                </div>
                <div className="border border-[#E4E4E4] rounded-[8px] py-10 pl-6 flex items-center gap-6">
                  <img
                    src={ActivitiesBlack}
                    alt=""
                    className="p-4 bg-[#62646A4D] rounded-full"
                    onClick={() => {
                      setSelectedName("activity");
                      selectedTime === "1w" &&
                        setSelected(stats?.oneWeekDoc[5]);
                      selectedTime === "1m" &&
                        setSelected(stats?.oneMonthDoc[5]);
                      selectedTime === "6m" &&
                        setSelected(stats?.sixMonthsDoc[5]);
                      selectedTime === "1yr" &&
                        setSelected(stats?.oneYearDoc[5]);
                    }}
                  />
                  <div className="flex flex-col gap-2">
                    <p className="font-medium text-[0.75rem] text-[#333333]">
                      ACTIVITIES COMPLETED
                    </p>
                    <p className="font-semibold text-3xl text-[#333333]">
                      {selectedTime === "1w" && stats?.oneWeek[5]}
                      {selectedTime === "1m" && stats?.oneMonth[5]}
                      {selectedTime === "6m" && stats?.sixMonths[5]}
                      {selectedTime === "1yr" && stats?.oneYear[5]}
                      {selectedTime === "all" && stats?.all[5]}
                    </p>
                    <p className="text-[#1E1E1EB2] font-medium text-[0.75rem]">
                      {selectedTime === "1w" && (
                        <span
                          className={`${
                            stats?.weekPercent[5] < 1
                              ? "text-[#B41007] bg-[#B410071A]"
                              : "text-[#3EB211] bg-[#6EDA441A]"
                          } font-medium text-[0.875rem] rounded-[100px] py-[2px] px-2`}
                        >
                          {stats?.weekPercent[5]}%
                        </span>
                      )}
                      {selectedTime === "1m" && (
                        <span
                          className={`${
                            stats?.monthPercent[5] < 1
                              ? "text-[#B41007] bg-[#B410071A]"
                              : "text-[#3EB211] bg-[#6EDA441A]"
                          } font-medium text-[0.875rem] rounded-[100px] py-[2px] px-2`}
                        >
                          {stats?.monthPercent[5]}%
                        </span>
                      )}
                      {selectedTime === "6m" && (
                        <span
                          className={`${
                            stats?.sixMonthsPercent[5] < 1
                              ? "text-[#B41007] bg-[#B410071A]"
                              : "text-[#3EB211] bg-[#6EDA441A]"
                          } font-medium text-[0.875rem] rounded-[100px] py-[2px] px-2`}
                        >
                          {stats?.sixMonthsPercent[5]}%
                        </span>
                      )}
                      {selectedTime === "1yr" && (
                        <span
                          className={`${
                            stats?.oneYearPercent[5] < 1
                              ? "text-[#B41007] bg-[#B410071A]"
                              : "text-[#3EB211] bg-[#6EDA441A]"
                          } font-medium text-[0.875rem] rounded-[100px] py-[2px] px-2`}
                        >
                          {stats?.oneYearPercent[5]}%
                        </span>
                      )}
                      {selectedTime === "all" && (
                        <span
                          className={`text-[#3EB211] bg-[#6EDA441A] font-medium text-[0.875rem] rounded-[100px] py-[2px] px-2`}
                        >
                          100%
                        </span>
                      )}{" "}
                      {selectedTime === "1w" && "Last One Week"}
                      {selectedTime === "1m" && "Last One Month"}
                      {selectedTime === "6m" && "Last Six Months"}
                      {selectedTime === "1yr" && "Last One Year"}
                      {selectedTime === "all" && "Total"}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <Dialog
        open={selected !== null}
        onClose={() => setSelected(null)}
        className="relative z-50"
      >
        {/* The backdrop, rendered as a fixed sibling to the panel container */}
        <div className="fixed inset-0 bg-black/30" aria-hidden="true" />

        {/* Full-screen container to center the panel */}
        <div className="fixed inset-0 flex items-center justify-center p-4">
          {/* The actual dialog panel  */}
          <Dialog.Panel className="mx-auto rounded bg-white p-4 h-[500px] lg:w-[700px] overflow-y-scroll">
            <div className="grid grid-cols-2 gap-4">
              {selected?.map((sel) => (
                <div
                  key={sel._id}
                  className="border rounded-lg border-[#333333] p-2 text-[0.75rem] font-poppins"
                >
                  <p className="mb-2 break-words">
                    <span className="font-semibold">User Email: </span>
                    {sel.email || sel.userEmail}
                  </p>
                  <p>
                    <span className="font-semibold">
                      {selectedName === "user"
                        ? "Join Date"
                        : selectedName === "reward"
                        ? "Reward"
                        : selectedName === "learn"
                        ? "Video Completed"
                        : selectedName === "quiz"
                        ? "Score"
                        : "Date Submitted"}
                      :{" "}
                    </span>
                    {selectedName !== "advocate" && selectedName !== "activity"
                      ? sel.score ||
                        sel.name ||
                        sel.amount ||
                        sel.title ||
                        convertDate(sel.createdAt)
                      : sel.date}
                  </p>
                </div>
              ))}
            </div>
            {selected?.length < 1 && (
              <div className="flex items-center justify-center w-[100%] h-[100%] font-poppins lg:text-[2.5rem] text-center text-[1.5rem] font-semibold text-gray-500">
                There is no data to display
              </div>
            )}
          </Dialog.Panel>
        </div>
      </Dialog>
    </div>
  );
}

export default Analytics;
