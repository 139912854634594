/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import Sorting from "../../../assets/new-design/f7_sort-up.svg";
import More from "../../../assets/new-design/uiw_more.svg";
import { Dialog } from "@headlessui/react";
import { CSVLink } from "react-csv";
import Close from "../../../assets/new-design/ic_round-close.svg";

function convertDate(isoDate) {
  //   const isoDate = "2023-06-23T20:37:03.658Z";

  // Create a new Date object from the ISO string
  const date = new Date(isoDate);

  // Extract day, month, and year
  const day = String(date.getDate()).padStart(2, "0");
  const month = date.toLocaleString("default", { month: "long" });
  const year = date.getFullYear();

  // Format the date as "dd mm, yyyy"
  const formattedDate = `${day} ${month}, ${year}`;

  return formattedDate; // Output: 23 June, 2023
}

function LearningTable({ data, addLearning, setAddLearning }) {
  const [isOpen, setIsOpen] = useState(false);
  const [selected, setSelected] = useState();
  console.log(selected);
  const CSVdata = selected ?? [];

  useEffect(() => {
    setIsOpen(addLearning);
  }, [addLearning]);

  return (
    <div className="w-[100%] mt-[24px]">
      <table className="w-[100%] hidden lg:block">
        <thead className="bg-[#F1F1F1]">
          <tr>
            <td className="pl-[16px] py-[8px] text-[#62646A99] font-poppins lg:w-[25%]">
              {" "}
              <div className="flex items-center gap-[8px]">
                Title
                <img src={Sorting} alt="" />
              </div>
            </td>
            <td className="py-[8px] text-[#62646A99] font-poppins lg:w-[25%]">
              {" "}
              <div className="flex items-center justify-center gap-[8px]">
                Category
                <img src={Sorting} alt="" />
              </div>
            </td>
            <td className="py-[8px] text-[#62646A99] font-poppins lg:w-[35%]">
              {" "}
              <div className="flex items-center gap-[8px]">
                Description
                <img src={Sorting} alt="" />
              </div>
            </td>
            <td className="py-[8px] text-[#62646A99] font-poppins lg:w-[10%]"></td>
          </tr>
        </thead>
        <tbody>
          {data?.map((da) => (
            <tr key={da._id}>
              <td className="pl-[16px] py-[16px] text-[#333333] font-medium font-poppins">
                {da.title}
              </td>
              <td className="py-[16px] text-[#333333] font-medium font-poppins text-center">
                {da.category}
              </td>
              <td className="py-[16px] text-[#333333] font-medium font-poppins">
                {da.description}
              </td>
              <td className="text-[#333333] font-medium font-poppins">
                <div
                  onClick={() => {
                    setSelected(da);
                    // setIsOpen(true);
                  }}
                  className="py-[16px] flex items-center justify-center cursor-pointer"
                >
                  <img src={More} alt="" />
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className="lg:hidden flex flex-col gap-[16px] w-[100%]">
        {data.map((da) => (
          <div
            key={da._id}
            className="flex flex-col border border-[#C8C8C8] rounded-lg p-4 space-y-6 font-poppins"
          >
            <div
              onClick={() => {
                setSelected(da);
                // setIsOpen(true);
              }}
              className="flex justify-end cursor-pointer"
            >
              <img src={More} alt="" />
            </div>
            <div className="flex items-center justify-between flex-wrap gap-6">
              <div>
                <p className="text-[0.75rem] text-[#62646A99] mb-1">TITLE</p>
                <p className="text-[0.875rem] text-[#333333] font-medium">
                  {" "}
                  {da.title}
                </p>
              </div>
              <div>
                <p className="text-[0.75rem] text-[#62646A99] mb-1">CATEGORY</p>
                <p className="text-[0.875rem] text-[#333333] font-medium">
                  {da.category}
                </p>
              </div>
            </div>
            <div className="flex items-center justify-between flex-wrap gap-6">
              <div>
                <p className="text-[0.75rem] text-[#62646A99] mb-1">
                  DESCRIPTION
                </p>
                <p className="text-[0.875rem] text-[#333333] font-medium">
                  {" "}
                  {da.description}
                </p>
              </div>
              <div>
                <p className="text-[0.75rem] text-[#62646A99] mb-1">
                  UPLOAD DATE
                </p>
                <p className="text-[0.875rem] text-[#333333] font-medium">
                  {convertDate(da.createdAt)}
                </p>
              </div>
            </div>
          </div>
        ))}
      </div>
      <Dialog
        open={isOpen}
        onClose={() => {
          setIsOpen(false);
          setAddLearning(false);
        }}
        className="relative z-50"
      >
        <div className="fixed inset-0 flex w-screen items-center bg-black/70 justify-center p-4">
          {addLearning && (
            <Dialog.Panel className="max-w-lg space-y-6 border bg-white lg:p-5 p-3 rounded-[8px] lg:w-[620px] w-[90%] font-poppins">
              <div className="flex items-center justify-end mb-[24px]">
                <img
                  className="cursor-pointer"
                  onClick={() => {
                    setIsOpen(false);
                    setAddLearning(false);
                  }}
                  src={Close}
                  alt=""
                />
              </div>
              <div className="flex flex-col items-center justify-center gap-[8px] border-b border-b-[#E4E4E4] pb-[24px]">
                <h1 className="text-center font-medium text-[1.125rem]">
                  Learning Details
                </h1>
              </div>
              <div className="border space-y-6 border-[#E4E4E4] rounded-[8px] p-[24px] mt-[24px]">
                <div className="flex flex-col gap-2">
                  <label
                    className="font-medium text-[0.75rem] text-[#62646A99]"
                    htmlFor=""
                  >
                    Title
                  </label>
                  <input
                    className="border border-[#333333] outline-none rounded px-3 py-1.5 text-[0.75rem]"
                    type="text"
                  />
                </div>
                <div className="flex flex-col gap-2">
                  <label
                    className="font-medium text-[0.75rem] text-[#62646A99]"
                    htmlFor=""
                  >
                    Video Link
                  </label>
                  <input
                    className="border border-[#333333] outline-none rounded px-3 py-1.5 text-[0.75rem]"
                    type="text"
                  />
                </div>
                <div className="flex flex-col gap-2">
                  <label
                    className="font-medium text-[0.75rem] text-[#62646A99]"
                    htmlFor=""
                  >
                    Category
                  </label>
                  <input
                    className="border border-[#333333] outline-none rounded px-3 py-1.5 text-[0.75rem]"
                    type="text"
                  />
                </div>
                <div className="flex flex-col gap-2">
                  <label
                    className="font-medium text-[0.75rem] text-[#62646A99]"
                    htmlFor=""
                  >
                    Description
                  </label>
                  <input
                    className="border border-[#333333] outline-none rounded px-3 py-1.5 text-[0.75rem]"
                    type="text"
                  />
                </div>
              </div>

              <button
                className="w-[100%] bg-[#3EB211] mt-[24px] text-white font-semibold text-[0.875rem] h-[44px] rounded-[6px]"
                onClick={() => setIsOpen(false)}
              >
                Save
              </button>

              <button
                className="w-[100%] bg-[#F1F1F1] text-[#333333] mt-[24px font-semibold text-[0.875rem] h-[44px] rounded-[6px]"
                onClick={() => setIsOpen(false)}
              >
                Cancel
              </button>
            </Dialog.Panel>
          )}
        </div>
      </Dialog>
    </div>
  );
}

export default LearningTable;
